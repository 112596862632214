import { memo } from "react";
import { useTranslation } from "react-i18next";
import IdentifierDot from "./IdentifierDot";

const ConversationIdentifier = memo(({ isClient }: { isClient?: boolean }) => {
  const { t } = useTranslation();
  return (
    <div className="flex gap-4 items-center ml-3">
      <IdentifierDot isClient={isClient} />
      <span>{isClient ? t("CLIENT") : t("NO_CLIENT")}</span>
    </div>
  );
});

export default ConversationIdentifier;
