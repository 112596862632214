import { useTranslation } from "react-i18next";
import { MessageModal } from "./MessageModal";
import { CheckCircleIcon, CheckIcon, PencilIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { MessageTypeEnum } from "../enums/MessageType";

interface Props {
    info: {
        show: boolean;
        messageType: number;
        message: string;
    };
    onClose: () => void;
}

export const SuccessErrorModal: React.FC<Props> = ({
    info,
    onClose
}) => {

    const { t } = useTranslation();

    return(
        <MessageModal
            isOpen={info.show}
            onClose={onClose}
            messageType={info.messageType}
        >
            <div className="w-full grid justify-items-center">
                { info.messageType === MessageTypeEnum.SUCCESS &&
                    <div className="mt-8">
                        <span className="w-20 text-green-500"><CheckCircleIcon/></span>
                        <div className="text-xl text-center font-medium mt-2">
                            {t('SUCCESS')}
                        </div>
                    </div>
                }
                { info.messageType === MessageTypeEnum.FAILED &&
                    <div className="mt-8 w-20">
                        <span className=" text-red-500"><XCircleIcon/></span>
                        <div className="text-xl text-center font-medium mt-2">
                            {t('FAILED')}
                        </div>
                    </div>
                }
                <div className="text-center mt-3 mb-5 mx-8">
                    {info.message}
                </div>
            </div>
        </MessageModal>
    )
}