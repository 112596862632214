import React, { useEffect, useState } from 'react';
import classNames from "classnames";
import Button from '../Button';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from "../../state/store";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fetchAgentTotalMembers } from '../../state/dashboard/actions';
import { LoadingMask } from '../LoadingMask';

enum SellsFilter {
    day,
    week,
    month,
    all
}

export const MembersSellsWidget = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: RootState) => state.users.currentUser);
    const { agentTotalMembers, membersSellFilter } = useSelector((state: RootState) => state.dashboard);
    const [reloadingAgentTotalMembers, setReloadingAgentTotalMembers] = useState(false);
    const [sellFilter, setSellFilter] = useState(membersSellFilter ?? SellsFilter.day)

    const getLocalTimeZone = () => {
        const timeZoneOffset = new Date().getTimezoneOffset();
    
        if (timeZoneOffset > 0) {
          const hours = Math.floor(timeZoneOffset / 60);
          const minutes = timeZoneOffset % 60;
          const sHours = `-${hours < 10 ? "0" : ""}${hours}:${String(minutes).padStart(2, "0")}`;
          return sHours;
        } else {
          const hours = Math.floor(timeZoneOffset / 60) * -1;
          const minutes = (timeZoneOffset % 60) * -1;
          const sHours = `+${hours < 10 ? "0" : ""}${hours}:${String(minutes).padStart(2, "0")}`;
          return sHours;
        }
    };

    useEffect(() => {
        if (currentUser) {
            const timeZone = getLocalTimeZone();
            let start;
            let end;

            if(sellFilter == SellsFilter.day) {
                const endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
                const startDate = moment(endDate).hours(0).minutes(0).seconds(0).toDate();
                
                end = moment(endDate).format("YYYY-MM-DD");
                start = moment(startDate).format("YYYY-MM-DD");
            } else if(sellFilter == SellsFilter.week) {
                const endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
                const startDate = moment(endDate).isoWeekday('Monday').hours(0).minutes(0).seconds(0).toDate();

                end = moment(endDate).format("YYYY-MM-DD");
                start = moment(startDate).format("YYYY-MM-DD");
            } else if(sellFilter == SellsFilter.month) {
                const endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
                const startDate = moment(endDate).date(1).hours(0).minutes(0).seconds(0).toDate();
                
                end = moment(endDate).format("YYYY-MM-DD");
                start = moment(startDate).format("YYYY-MM-DD");
            } else if(sellFilter == SellsFilter.all) {
                start = undefined;
                end = undefined;
            }
        
            const params = {startDate: start, endDate: end, timeZone: timeZone, sellsFliter: sellFilter};
            dispatch(fetchAgentTotalMembers(params));
        }
      }, [currentUser, dispatch]);

      const handleChangeSellsFilter = (filter: SellsFilter) => {
        setSellFilter(filter);
        reloadAgentTotalMembers(filter);
    }

    const reloadAgentTotalMembers = (filter: SellsFilter) => {
        setReloadingAgentTotalMembers(true);
        const timeZone = getLocalTimeZone();
        let start;
        let end;

        if(filter == SellsFilter.day) {
            const endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
            const startDate = moment(endDate).hours(0).minutes(0).seconds(0).toDate();
            
            end = moment(endDate).format("YYYY-MM-DD");
            start = moment(startDate).format("YYYY-MM-DD");
        } else if(filter == SellsFilter.week) {
            const endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
            const startDate = moment(endDate).isoWeekday('Monday').hours(0).minutes(0).seconds(0).toDate();
            
            end = moment(endDate).format("YYYY-MM-DD");
            start = moment(startDate).format("YYYY-MM-DD");
        } else if(filter == SellsFilter.month) {
            const endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
            const startDate = moment(endDate).date(1).hours(0).minutes(0).seconds(0).toDate();
            
            end = moment(endDate).format("YYYY-MM-DD");
            start = moment(startDate).format("YYYY-MM-DD");
        } else if(filter == SellsFilter.all) {
            start = undefined;
            end = undefined;
        }
    
        const params = {startDate: start, endDate: end, timeZone: timeZone, sellsFliter: filter};
        dispatch(fetchAgentTotalMembers(params)).then(() => {
          setReloadingAgentTotalMembers(false);
        });
    }

    return (
        <>
            {(reloadingAgentTotalMembers) && <LoadingMask />}
            <div className="bg-white flex flex-col items-center justify-center relative">
                <Button
                    iconOnly
                    onClick={() => reloadAgentTotalMembers(sellFilter)}
                    isTerciary
                    classNames="absolute top-5 start-5"
                >
                    <div className="w-6 aspect-square">
                        <ArrowPathIcon />
                    </div>
                </Button>
                <div className="text-gray-500 text-3xl mb-7">{t("TOTAL_MEMBER_SELLS")}</div>
                <div className="text-gold text-8xl font-semibold">{agentTotalMembers.totalMembers}</div>
                <div className="text-blue font-light text-2xl my-4">{t("POLICIES_COUNT")}: {agentTotalMembers.totalPolicies}</div>
                <div className="flex flex-row items-center">
                    <div className={classNames({
                        "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-5 py-1": true,
                        "bg-neutral-200": sellFilter === SellsFilter.day,
                    })} style={{ margin: '-1px' }} onClick={() => handleChangeSellsFilter(SellsFilter.day)}>
                        <div className="text-gray-500">{t("SHOW")}</div>
                        <div className="text-blue text-3xl font-medium">1D</div>
                    </div>
                    <div className="bg-neutral-200 h-10 w-px"></div>
                    <div className={classNames({
                        "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-5 py-1": true,
                        "bg-neutral-200": sellFilter === SellsFilter.week,
                    })} style={{ margin: '-1px' }} onClick={() => handleChangeSellsFilter(SellsFilter.week)}>
                        <div className="text-gray-500">{t("SHOW")}</div>
                        <div className="text-blue text-3xl font-medium">1W</div>
                    </div>
                    <div className="bg-neutral-200 h-10 w-px"></div>
                    <div className={classNames({
                        "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-5 py-1": true,
                        "bg-neutral-200": sellFilter === SellsFilter.month,
                    })} style={{ margin: '-1px' }} onClick={() => handleChangeSellsFilter(SellsFilter.month)}>
                        <div className="text-gray-500">{t("SHOW")}</div>
                        <div className="text-blue text-3xl font-medium">1M</div>
                    </div>
                    <div className="bg-neutral-200 h-10 w-px"></div>
                    <div className={classNames({
                        "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-5 py-1": true,
                        "bg-neutral-200": sellFilter === SellsFilter.all,
                    })} style={{ margin: '-1px' }} onClick={() => handleChangeSellsFilter(SellsFilter.all)}>
                        <div className="text-gray-500">{t("SHOW")}</div>
                        <div className="text-blue text-3xl font-medium">All</div>
                    </div>
                </div>
            </div>
        </>
    )
}