import { ArrowRightOnRectangleIcon, ArrowsPointingOutIcon, ArrowUturnLeftIcon, MicrophoneIcon, PhoneXMarkIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import { useMemo } from "react";
import Draggable from "react-draggable";
import { CallDetails } from "../../models/TwilioConversation";
import Button from "../Button";
import { useSelector } from "react-redux";

interface Props {
    decline: () => void;
    callDetails: CallDetails;
    muteCall: () => void;
    unmuteCall: () => void;
    transfer: () => void;
    transferFinalStep: (accepted: boolean) => void;
    conference: () => void;
    isMute?: boolean;
}
export const Call = (props: Props) => {
    const twilio = useSelector((state: any) => state.twilio);

    const callName = useMemo(() => {
        if (props.callDetails.name && props.callDetails.name !== "") {
            return props.callDetails.name;
        }
        return "Unknown";
    }, [props.callDetails]);

    return <Draggable handle="strong">
        <div className="bg-blue absolute z-40 top inset-1/2 -translate-y-2/4 -translate-x-2/4 rounded-lg w-fit h-fit p-4 text-white min-w-[200px]">
            <div className="flex justify-between mb-4">
                <div>
                    <p className="text-l font-semibold">{callName}</p>
                    <p className="opacity-40 text-sm -mt-1">{props.callDetails.phone}</p>
                </div>
                <strong className="cursor"><ArrowsPointingOutIcon className="w-5 h-5 cursor-pointer z-40" /></strong>
            </div>
            <div className="flex justify-between">
                <div className="flex h-fit items-center">
                    {twilio?.transferConferenceId !== ""
                        ? <div className="flex gap-2">
                            <Button
                                tooltip={t("BACK_TO_CLIENT")}
                                tooltipClassName="bg-gray top-10 h-fit left-[-2rem]"
                                onClick={() => props.transferFinalStep(false)}
                                isSecondary
                                iconOnly
                                classNames="bg-neutral-200 hover:bg-opacity-40 bg-opacity-20">
                                <ArrowUturnLeftIcon className="w-5 h-5 cursor-pointer z-40" />
                            </Button>
                            <Button
                                tooltip={t("TRANSFER_CALL")}
                                tooltipClassName="bg-gray top-10 h-fit left-[-2rem]"
                                onClick={() => props.transferFinalStep(true)}
                                isSecondary
                                iconOnly
                                classNames="bg-neutral-200 hover:bg-opacity-40 bg-opacity-20">
                                <ArrowRightOnRectangleIcon className="w-5 h-5 cursor-pointer z-40" />
                            </Button>
                        </div>
                        : <Button
                            tooltip={t("TRANSFER_CALL")}
                            tooltipClassName="bg-gray top-10 h-fit left-[-2rem]"
                            onClick={props.transfer}
                            isSecondary
                            iconOnly
                            classNames="bg-neutral-200 hover:bg-opacity-40 bg-opacity-20">
                            <ArrowRightOnRectangleIcon className="w-5 h-5 cursor-pointer z-40" />
                        </Button>
                    }
                    <div>
                        <Button
                            tooltip={t("CONFERENCE")}
                            tooltipClassName="bg-gray top-10 h-fit left-[-2rem]"
                            onClick={props.conference}
                            isSecondary
                            iconOnly
                            classNames="bg-neutral-200 hover:bg-opacity-40 bg-opacity-20 ml-4">
                            <UserPlusIcon className="w-5 h-5 cursor-pointer z-40" />
                        </Button>
                    </div>
                </div>
                <div className="flex h-fit items-center ml-10">
                    {props.isMute
                        ? <Button onClick={props.unmuteCall} isSecondary iconOnly classNames="bg-neutral-200 bg-opacity-40 hover:bg-opacity-20">
                            <div className="relative flex justify-center items-center">
                                <MicrophoneIcon className="w-5 h-5 cursor-pointer z-40" />
                                <div className="bg-neutral-200 h-2 aspect-square absolute transform rotate-45 h-6 w-[1px]"></div>
                            </div>
                        </Button>
                        : <Button onClick={props.muteCall} isSecondary iconOnly classNames="bg-neutral-200 hover:bg-opacity-40 bg-opacity-20"><MicrophoneIcon className="w-5 h-5 cursor-pointer z-40" /></Button>
                    }
                    {twilio?.transferConferenceId === "" && <Button onClick={props.decline} isSecondary iconOnly classNames="ml-4 bg-red-600 bg-opacity-100 hover:bg-opacity-80 hover:bg-red-600">
                        <PhoneXMarkIcon className="w-5 h-5 cursor-pointer z-40" />
                    </Button>
                    }
                </div>
            </div>
        </div>
    </Draggable>
}