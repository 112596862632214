import classNames from "classnames";

interface SideBarItemProps {
  children: React.ReactNode;
  collapsed: boolean;
  selectedOption: number;
  itemKey: number;
  onClick: () => void;
}

const SideBarItem = ({ children, ...props }: SideBarItemProps) => {
  return (
    <button
      onClick={props.onClick}
      className={classNames({
        "hover:text-gold hover:bg-white flex my-1": true, //colors
        "transition-colors duration-300 cursor-pointer": true, //animation
        "p-4 mx-4 gap-4 rounded-lg hover:text-gold": !props.collapsed,
        "rounded-full p-2 mx-3 w-10 h-10 hover:bg-gold":
          props.collapsed,
        "text-gold bg-white": props.selectedOption === props.itemKey,
      })}
    >
      {children}
    </button>
  );
};

export default SideBarItem;
