import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { getPolicyStates } from "../../enums/PolicyState";
import {
  EditPolicy,
  NewPolicy,
  newPolicyInitialValues,
} from "../../models/Policies";
import { Modal } from "../Modal";
import { ReactiveFormInput } from "../form/ReactiveFormInput";
import { ReactiveFormRadioButtonSelect } from "../form/ReactiveFormRadioButtonSelect";
import { ReactiveFormSelect } from "../form/ReactiveFormSelect";
import { getCarriers } from "../../enums/Carrier";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { RoleEnum } from "../../enums/Role";
import { User } from "../../models/User";
import { AgentCommissionRule } from "../../models/AgentCommissionRule";
import { fetchAgencyUserCommissionRules } from "../../state/agencies/action";
import { AppDispatch } from "../../state/store";

interface Props {
  addPolicy: (policy: EditPolicy | NewPolicy) => void;
  updatePolicy: (index: number, policy: EditPolicy | NewPolicy) => void;
  info: {
    show: boolean;
    update: boolean;
    policy?: EditPolicy | NewPolicy;
    index?: number;
  };
  onClose: () => void;
}

export const ClientPolicyModal: React.FC<Props> = ({
  addPolicy,
  info,
  onClose,
  updatePolicy,
}) => {
  const { t } = useTranslation();

  const methods = useForm<EditPolicy>({
    defaultValues: newPolicyInitialValues,
  });
  const { handleSubmit, control, getValues, reset } = methods;

  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const agents = useSelector((state: any) => state.users.users);

  const [commissionRules, setCommissionRules] = useState<AgentCommissionRule[]>([]);
  const [selectedCommissionRule, setSelectedCommissionRule] = useState<AgentCommissionRule | undefined>(undefined);
  const [loadingRules, setLoadingRules] = useState(false);

  useEffect(() => {
    info.policy ? reset(info.policy) : reset(newPolicyInitialValues);

    if (info.policy && info.policy.agent_id) {
      if (agents.find((x: any) => x.id === info.policy!.agent_id)) {
        setLoadingRules(true)
        dispatch(fetchAgencyUserCommissionRules({agencyId: currentUser.agency_id, userId: +info.policy.agent_id!})).then((e) => {
          let rules = (e.payload || []) as AgentCommissionRule[];
          setCommissionRules(rules)

          if (info.policy?.agent_commission_rule_id) {
            setSelectedCommissionRule(rules.find((x: AgentCommissionRule) => x.id === info.policy?.agent_commission_rule_id))
          }

          setLoadingRules(false)
        })
      } else {
        info.policy.agent_id = undefined;
      }
    }
  }, [info.policy, reset]);
  
  const onSubmitInvalid: SubmitErrorHandler<EditPolicy> = (data) => {
    toast(t("PLEASE_ENTER_ALL_POLICY_REQUIRED_FIELDS"));
  }

  const onSubmit: SubmitHandler<EditPolicy> = async (data: EditPolicy) => {
    info.update
      ? updatePolicy(Number(info.index), data)
      : addPolicy(data);
    closeModal();
  };

  const closeModal = () => {
    reset();
    setSelectedCommissionRule(undefined);
    setCommissionRules([])
    onClose();
  };

  return (
    <Modal
      isOpen={info.show}
      onClose={closeModal}
      label={info.update ? t("UPDATE_POLICY") : t("ADD_POLICY")}
      onSave={handleSubmit(onSubmit, onSubmitInvalid)}
    >
      <div className="w-full flex flex-wrap">
        <Fragment>
          <ReactiveFormInput
            control={control}
            className="md:w-1/3"
            label={t("POLICY_NUMBER")}
            name={`number`}
          />
          <ReactiveFormSelect
            className="md:w-1/3"
            name={`carrier_id`}
            label={t("CARRIER")}
            options={getCarriers()}
            control={control}
            isRequired
          />
          <ReactiveFormInput
            control={control}
            type="date"
            className="md:w-1/3"
            label={t("START_DATE")}
            name={`effective_start_date`}
            isRequired
          />
          <ReactiveFormInput
            control={control}
            type="date"
            className="md:w-1/4"
            label={t("END_DATE")}
            name={`effective_end_date`}
            isRequired
          />
          <ReactiveFormInput
            control={control}
            className="md:w-1/4"
            label={t("MEMBERS_INSURED")}
            name={`members_insured`}
            type="number"
            isRequired
          />
          <ReactiveFormSelect
            className="md:w-1/4"
            name={`policy_state`}
            label={t("STATUS")}
            options={getPolicyStates()}
            isDisabled={getValues("is_new")}
            control={control}
            isRequired
          />
          <div className="flex md:w-1/4">
            <ReactiveFormRadioButtonSelect
              className="mr-2"
              control={control}
              name={`free_plan`}
              label={t("FREE_PLAN")}
              options={[
                { label: t("YES"), value: "1" },
                { label: t("NO"), value: "0" },
              ]}
            />
          </div>
        </Fragment>
        { currentUser?.role_id === RoleEnum.SUPER_ADMIN && <Fragment>
          <ReactiveFormSelect
            className="md:w-1/3"
            name={`agent_id`}
            label={t("AGENT")}
            options={agents.map((x: User) => { return {label: `${x.first_name} ${x.last_name}`, value: `${x.id}`} })}
            control={control}
            handleOnChange={(x: any) => {
              methods.resetField("agent_commission_rule_id")
              setSelectedCommissionRule(undefined)
              setLoadingRules(true)
              dispatch(fetchAgencyUserCommissionRules({agencyId: currentUser.agency_id, userId: +x.target.value})).then((e) => {
                  setCommissionRules((e.payload || []) as AgentCommissionRule[])
                  setLoadingRules(false)
              })
            }}
            isRequired
          />

          <ReactiveFormSelect
            className="md:w-1/3"
            name={`agent_commission_rule_id`}
            label={t("AGENT_COMMISSION_RULE")}
            options={commissionRules.map((r: AgentCommissionRule) => { return {label: `${r.name} - ${r.is_relative ? r.value!*100+'%' : '$'+r.value}`, value: `${r.id}`} })}
            control={control}
            isDisabled={methods.getValues("agent_id") === undefined || loadingRules}
            isRequired
            handleOnChange={(e: any) => setSelectedCommissionRule(commissionRules.find((x: AgentCommissionRule) => x.id === +e.target.value))}
          />

          { selectedCommissionRule !== undefined && 
            <p className="text-slate-500 text-xs px-3">{selectedCommissionRule.description}</p> }
        </Fragment>}

        { currentUser?.role_id !== RoleEnum.SUPER_ADMIN && info.update && <Fragment>
          <ReactiveFormSelect
            className="md:w-1/3"
            name={`agent_id`}
            label={t("AGENT")}
            options={agents.map((x: User) => { return {label: `${x.first_name} ${x.last_name}`, value: `${x.id}`} })}
            control={control}
            isDisabled
          />

          <ReactiveFormSelect
            className="md:w-1/3"
            name={`agent_commission_rule_id`}
            label={t("AGENT_COMMISSION_RULE")}
            options={commissionRules.map((r: AgentCommissionRule) => { return {label: `${r.name} - ${r.is_relative ? r.value!*100+'%' : '$'+r.value}`, value: `${r.id}`} })}
            control={control}
            isDisabled
          />

          { selectedCommissionRule !== undefined && 
            <p className="text-slate-500 text-xs px-3">{selectedCommissionRule.description}</p> }
        </Fragment>}
      </div>
    </Modal>
  );
};
