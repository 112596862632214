import { ChevronDownIcon, MinusIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

interface Props {
  blocks: {
    label: string;
    info: React.ReactNode;
    isOpen?: boolean;
    handleChange: () => void;
  }[];
}

export const Accordion = (props: Props) => {
  return (
    <>
      <div className="w-full grow p-5 flex flex-col">
        <div className="space-y-4">
          {props.blocks?.map((element) => {
            return (
              <div
                key={element.label}
                className={classNames({
                  "text-black p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden":
                    true,
                  "max-h-16": !element.isOpen,
                  "max-h-full": element.isOpen,
                })}
              >
                <div
                  className="px-3 pb-3 flex cursor-pointer justify-between items-center "
                  onClick={element.handleChange}
                >
                  <div
                    className={classNames({
                      "text-xl font-bold text-blue": true,
                    })}
                  >
                    {element.label}
                  </div>
                  {element.isOpen ? (
                    <MinusIcon className="w-6 h-6 text-gold" strokeWidth={3} />
                  ) : (
                    <ChevronDownIcon
                      className="w-6 h-6 text-gold"
                      strokeWidth={3}
                    />
                  )}
                </div>
                <div className="pb-5">{element.info}</div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
