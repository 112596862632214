import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { MessageTypeEnum } from "../enums/MessageType";

interface MessageModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: any;
    messageType: number;
  }

  export const MessageModal: React.FC<MessageModalProps> = ({ isOpen, onClose, children, messageType }) => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
  
    useEffect(() => {
      setIsModalOpen(isOpen);
    }, [isOpen]);
  
    return (
      <>
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center z-50 min-h-[50%]">
            <div className="fixed inset-0 bg-black opacity-50"></div>
  
            <div
              className="relative flex md:w-2/6 w-2/6 min-h-2/6 flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none">
              <div className="relative flex-auto p-4" data-te-modal-body-ref>
                {children}
              </div>
              <div
                className="flex flex-shrink-0 flex-wrap items-center justify-center rounded-b-md pb-10">
                  { messageType === MessageTypeEnum.SUCCESS &&
                    <Button onClick={onClose} classNames='text-black'>
                      <span className='flex items-center font-semibold pr-3'>
                        <div className='w-8 p-1 aspect-square mr-2'><CheckIcon /></div>
                        {t('CONTINUE')}
                      </span>
                    </Button>
                  }
                  { messageType === MessageTypeEnum.FAILED &&
                    <Button isTerciary onClick={onClose} classNames='text-black'>
                      <span className='flex items-center font-semibold px-3 py-1'>
                        {t('KEEP_EDITING')}
                      </span>
                    </Button>
                  }
              </div>
            </div>
          </div>
        )}
      </>
    );
};