import { CheckIcon, PlusIcon } from '@heroicons/react/24/outline';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Button from '../../components/Button';
import { LoadingMask } from '../../components/LoadingMask';
import { Form } from '../../components/form/Form';
import { ReactiveFormInput } from '../../components/form/ReactiveFormInput';
import { ReactiveFormPhoneInput } from '../../components/form/ReactiveFormPhoneInput';
import { ReactiveFormSelect } from '../../components/form/ReactiveFormSelect';
import { UsersPath } from '../../constants/Routes';
import { RoleEnum, getRoles } from '../../enums/Role';
import { EditUser, User } from '../../models/User';
import { AppDispatch } from '../../state/store';
import { updateUser } from '../../state/users/actions';
import { Toast } from '../../components/Toast';
import { getBusiness } from '../../enums/Business';
import { useEffect, useState } from 'react';
import { createAgencyUserCommissionRule, fetchAgencyUserCommissionRules, updateAgencyUserCommissionRule } from '../../state/agencies/action';
import { AgentCommissionRule } from '../../models/AgentCommissionRule';
import { Modal } from '../../components/Modal';
import { fetchCommissionRuleTypes } from '../../state/commissions/action';
import { CommissionRuleType } from '../../models/CommissionRule';
import { getCarriers } from '../../enums/Carrier';
import { FormSelect } from '../../components/form/FormSelect';
import { FormInput } from '../../components/form/FormInput';

export const UpdateUser = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const dispatch = useDispatch<AppDispatch>();
    const isLoading = useSelector((state: any) => state.users.isLoading);
    const error = useSelector((state: any) => state.users.error);
    const location = useLocation();
    const user = location.state.user;
    const methods = useForm<EditUser>({
        defaultValues: user
    });
    const { handleSubmit, control } = methods;

    const createRuleMethods = useForm<any>({
        defaultValues: {}
    });
    const { handleSubmit: handleCreateRuleSubmit, control: createRuleControl } = createRuleMethods;

    const [commissionRules, setCommissionRules] = useState<AgentCommissionRule[]>([]);
    const [isOpenCreateRuleModal, setIsOpenCreateRuleModal] = useState(false);

    const [commissionRuleTypes, setCommissionRuleTypes] = useState<CommissionRuleType[]>([]);
    const [selectedCommissionRuleType, setSelectedCommissionRuleType] = useState<CommissionRuleType | undefined>();

    const [selectedAgents, setSelectedAgents] = useState<{agent: User, value: number}[]>([]);
    const [selectedAgentsEmptyError, setSelectedAgentsEmptyError] = useState(false);

    const agents = useSelector((state: any) => state.users.users);

    let sumOfValues = () => selectedAgents.reduce((a, b) => a + b.value, 0);
    let currentAgentValue = () => +(createRuleMethods.getValues("value") ?? 0);

    useEffect(() => {
        dispatch(fetchAgencyUserCommissionRules({agencyId: currentUser.agency_id, userId: user.id})).then((e) => {
            setCommissionRules((e.payload || []) as AgentCommissionRule[])
        })

        dispatch(fetchCommissionRuleTypes({apiPath: '/commission-rule-types', includeInactive: false})).then(
            x => setCommissionRuleTypes(x.payload as CommissionRuleType[] ?? []));
    }, [user]);

    const onSubmit: SubmitHandler<EditUser> = async (data: EditUser) => {
        const updatedData = data;
        if (data.role_id) {
            updatedData.role_id = +data.role_id;
        }
        if (data.commission_level) {
            updatedData.commission_level = +data.commission_level;
        }
        if(data.business_id) {
            updatedData.business_id = +data.business_id;
        }
        dispatch(updateUser({ user: updatedData, agencyId: currentUser.agency_id })).then((e) => {
            if (e.type === "users/updateUser/rejected") {
                Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info);
            } else {
                toast(t("SAVED_SUCCESFULLY"));
                navigate(UsersPath)
            }
        })
    };

    const onCreateRuleSubmit: SubmitHandler<AgentCommissionRule> = async (data: AgentCommissionRule) => {
        if (data.carrier_id) {
            data.carrier_id = +data.carrier_id;
        }

        if (data.value) {
            data.value = selectedCommissionRuleType?.is_relative ? (+data.value)/100 : +data.value;
        }

        if (data.commission_rule_type_id) {
            data.commission_rule_type_id = +data.commission_rule_type_id;
        }

        if (selectedCommissionRuleType?.id === 4) {
            if (selectedAgents.length === 0) {
                return setSelectedAgentsEmptyError(true)
            }
        }

        let rules: {user_id: number, data: AgentCommissionRule}[] = []

        if (selectedCommissionRuleType?.id === 4) {
            rules = [
                {
                    user_id: user.id,
                    data: {
                        name: data.name,
                        commission_rule_type_id: selectedCommissionRuleType.id,
                        value: data.value,
                    }
                },
                ...selectedAgents.map((x) => {
                    return {
                        user_id: x.agent.id,
                        data: {
                            commission_rule_type_id: selectedCommissionRuleType?.id,
                            value: x.value,
                            name: `${data.name} (${x.agent.first_name})`
                        }
                    }
                })
            ]
        } else {
            rules = [
                {
                    user_id: user.id,
                    data: {
                        name: data.name,
                        commission_rule_type_id: selectedCommissionRuleType?.id,
                        value: data.value
                    }
                }
            ]
        }

        dispatch(createAgencyUserCommissionRule({ agencyId: currentUser.agency_id, userId: user.id, payload: rules })).then((e) => {
            if (e.type === "agencies/createAgencyUserCommissionRule/rejected") {
                Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info);
            } else {
                toast(t("SAVED_SUCCESFULLY"));

                setCommissionRules((e.payload || []) as AgentCommissionRule[])

                setIsOpenCreateRuleModal(false)
                setSelectedAgents([])
                setSelectedCommissionRuleType(undefined);
                createRuleMethods.reset();
                setSelectedAgentsEmptyError(false)
            }
        })
    };

    const showCreateRuleModal = () => {
        return <Modal isOpen={isOpenCreateRuleModal} 
            onClose={() => {
                setIsOpenCreateRuleModal(false)
                setSelectedAgents([])
                setSelectedCommissionRuleType(undefined);
                createRuleMethods.reset();
                setSelectedAgentsEmptyError(false)
            }} label={t("NEW_COMMISSION_RULE")} onSave={handleCreateRuleSubmit(onCreateRuleSubmit)}>
            
            <div className='flex flex-col space-y-3'>
                <div className='w-full flex items-start'>
                    <div className='md:w-1/2'>
                        <ReactiveFormInput
                            control={createRuleControl}
                            label={t("NAME")}
                            name="name"
                            isRequired></ReactiveFormInput>
                    </div>
                    <div className='md:w-1/2'>
                        <ReactiveFormSelect
                            control={createRuleControl}
                            label={t("COMMISSION_RULE_TYPE")}
                            name="comission_rule_type_id"
                            options={commissionRuleTypes.map(el => {
                                return ({ value: `${el.id}`, label: `${el.name}` })
                            })}
                            handleOnChange={(e) => {
                                createRuleMethods.setValue('value', '')
                                setSelectedCommissionRuleType(commissionRuleTypes.find(el => el.id == e.target.value))
                            }}
                            isRequired></ReactiveFormSelect>

                        <p className="text-slate-500 text-xs px-3 block">
                            {`${selectedCommissionRuleType?.description ?? ''}`}
                        </p>
                    </div>
{/* 
                    <ReactiveFormSelect
                        control={createRuleControl}
                        className='md:w-1/2'
                        label={t("CARRIER")}
                        name="carrier_id"
                        options={getCarriers().filter(x => commissionRules.find(y => y.carrier_id === x.key) === undefined)}
                        isRequired /> */}

                </div>

                <div className='w-full flex items-center'>
                    <ReactiveFormInput
                        control={createRuleControl}
                        className='md:w-1/4'
                        label={t("VALUE")}
                        placeholder={selectedCommissionRuleType?.is_relative === false ? '$' : '%'}
                        name="value"
                        type='number'
                        min={selectedCommissionRuleType?.id !== 3 ? 1 : undefined}
                        max={selectedCommissionRuleType?.id === 4 ? 100 - sumOfValues() : 
                                selectedCommissionRuleType?.id === 1 ? 100 : 
                                selectedCommissionRuleType?.id == 3 ? -1 : undefined}
                        isRequired />
                </div>

                { selectedCommissionRuleType?.id === 4 && <div className='w-full flex flex-col space-y-1'>
                    <p className='font-medium text-md p-3'>{t("OTHER_AGENTS")}</p>
                    <div className='w-full divide-y divide-dashed'>
                        {selectedAgents.map(x => <div className='flex p-3'>
                            <p className='md:w-1/2'>{x.agent.first_name} {x.agent.last_name}</p>
                            <p className='md:w-1/2 font-semibold'>{x.value}%</p>
                        </div>)}

                        <div className='py-3 w-full flex items-start'>
                            <div className='md:w-1/2'>
                                <ReactiveFormSelect
                                    
                                    label={t("AGENT")}
                                    name="selectedOtherAgent"
                                    control={createRuleControl}
                                    options={agents
                                        .filter((x: User) => x.role_id === RoleEnum.AGENT && x.id !== user.id && selectedAgents.find(y => y.agent.id == x.id) === undefined)
                                        .map((x:User) => { return {label: `${x.first_name} ${x.last_name}`, value: `${x.id}`} })}/>
                                {selectedAgentsEmptyError && <p className="text-red-500 text-xs italic ml-3">{t('NO_OTHER_AGENTS_SELECTED')}</p>}
                            </div>

                            <ReactiveFormInput
                                control={createRuleControl}
                                className='md:w-1/4'
                                label={t("VALUE")}
                                placeholder={'%'}
                                name="selectedOtherAgentValue"
                                min={1}
                                max={100 - (sumOfValues() + currentAgentValue())}
                                type='number'/>

                            <Button classNames='mt-8' onClick={async () => {
                                const result = await createRuleMethods.trigger(['selectedOtherAgent', 'selectedOtherAgentValue'])

                                if (!result) { return }

                                let selectedUser = agents.find((x: User) => x.id == createRuleMethods.getValues("selectedOtherAgent"))
                                let value = +createRuleMethods.getValues("selectedOtherAgentValue")

                                if (!selectedUser) {
                                    createRuleMethods.setError('selectedOtherAgent', {message: t('FIELD_REQUIRED')})
                                    return
                                }

                                if (isNaN(value)) {
                                    createRuleMethods.setError('selectedOtherAgentValue', {message: t('FIELD_REQUIRED')})
                                    return
                                }

                                
                                createRuleMethods.resetField('selectedOtherAgent')
                                createRuleMethods.resetField('selectedOtherAgentValue', {defaultValue: ''})

                                setSelectedAgentsEmptyError(false)

                                setSelectedAgents(prev => {
                                    let copy = [...prev]
                                    copy.push({agent: selectedUser,  value: value})
                                    
                                    return copy;
                                })

                                
                            }}>
                                <span className='flex items-center font-semibold pr-3'>
                                    <div className='w-8 p-1 aspect-square mr-2'><PlusIcon /></div>
                                    {t('ADD')}
                                </span>
                            </Button>
                        </div>
                        
                    </div>
                    
                </div>}
            </div>
            
        </Modal>
    }

    /* const showEditRuleModal = () => {
        return <Modal isOpen={isOpenUpdateModal} onClose={() => setIsOpenUpdateModal(false)} 
            label={t("EDIT_COMMISSION_RULE_TYPE")} onSave={handleUpdateSubmit(onUpdateSubmit)}>
            <div className='w-full flex flex-wrap'>
                <ReactiveFormInput
                    control={updateControl}
                    label={t("NAME")}
                    name="name"
                    isRequired />
            </div>
            <div>    
                <ReactiveFormInputArea
                    control={updateControl}
                    label={t("DESCRIPTION")}
                    name="description"
                    isRequired />
            </div>
            <div>
                <ReactiveFormCheckbox
                    options={[
                        {label: t("IS_ACTIVE"), value: "true"}
                    ]}
                    control={updateControl}
                    name="is_active"
                />
            </div>
        </Modal>
    } */

    const setRuleAsDefault = (rule: AgentCommissionRule) => {
        if (rule.is_default) {
            return;
        }

        setCommissionRules((prev: AgentCommissionRule[]) => {
            prev.forEach((r: AgentCommissionRule) => r.is_default = false)
            prev[prev.findIndex((x: AgentCommissionRule) => x.id === rule.id)].is_default = true;
            return [...prev];
        })

        dispatch(updateAgencyUserCommissionRule({ agencyId: currentUser.agency_id, userId: user.id, ruleId: rule.id!, payload: {is_default: true} })).then((e) => {
            if (e.type === "agencies/updateAgencyUserCommissionRule/rejected") {
                Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info);
            } else {
                toast(t("SAVED_SUCCESFULLY"));

                setCommissionRules((e.payload || []) as AgentCommissionRule[])
            }
        })
    }

    return (<>
        {showCreateRuleModal()}
        <div className="w-full grow px-5 space-y-4 flex flex-col">
            {isLoading && <LoadingMask />}
            <ToastContainer progressStyle={{ "background": "#D4AF37" }} />
            <div className="text-black max-h-full p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden">
                <div className="text-xl font-bold text-black pl-3">
                    {t("USER")}
                </div>
                <div className="pb-5">
                    <Form handleOnSubmit={handleSubmit(onSubmit)}>
                        <div className='w-full text-lg font-semibold p-3'>{t("PERSONAL_INFORMATION")}</div>
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("FIRST_NAME")}
                            name="first_name"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("LAST_NAME")}
                            name="last_name"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("SOCIAL_SECURITY_NUMBER")}
                            name="social_security_number"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            type='date'
                            className='md:w-1/3'
                            label={t("DATE_OF_BIRTH")}
                            name="date_of_birth"
                            isRequired />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("EMAIL")}
                            name="email"
                            type='email'
                            isDisabled />
                        <ReactiveFormPhoneInput
                            control={control}
                            className='md:w-1/3'
                            name="phone"
                            label={t("PHONE")}
                            isRequired
                        />
                        <div className='w-full text-lg font-semibold p-3'>{t("AGENT_INFORMATION")}</div>
                        <ReactiveFormSelect
                            className="md:w-1/3"
                            name={`role_id`}
                            label={t("ROLE")}
                            options={getRoles(currentUser?.role_id)}
                            control={control}
                            isRequired
                        />
                        <ReactiveFormSelect
                            className="md:w-1/3"
                            name={`business_id`}
                            label={t("BUSINESS")}
                            options={getBusiness()}
                            control={control}
                            isRequired
                        />
                        <ReactiveFormInput
                            control={control}
                            className='md:w-1/3'
                            label={t("NATIONAL_PRODUCER_NUMBER")}
                            name={`national_producer_number`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("AKA_LICENSE")}
                            name={`aka_license`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("UPLINE")}
                            name={`upline`}
                        />
                        <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("OVERRIDES")}
                            name={`overrides`}
                        />
                        {/* <ReactiveFormInput
                            control={control}
                            className="md:w-1/3"
                            label={t("COMMISSION_LEVEL")}
                            name={`commission_level`}
                            type='number'
                        /> */}

                        {currentUser.role_id === RoleEnum.SUPER_ADMIN && <>

                            <div className='w-full text-lg font-semibold p-3 flex justify-between'>
                                {t("COMMISSIONS")}
                                <Button onClick={() => setIsOpenCreateRuleModal(true)}>
                                    <span className='flex items-center font-semibold pr-3'>
                                        <div className='w-8 p-1 aspect-square mr-2'><CheckIcon /></div>
                                        {t('NEW_COMMISSION_RULE')}
                                    </span>
                                </Button>
                            </div>
                            <div className='px-3 grid grid-cols-3 gap-6'>
                            {
                                commissionRules.map(r => <>
                                    <div 
                                        onClick={() => setRuleAsDefault(r) }
                                        className='py-4 px-6 max-w-sm mx-auto bg-white rounded-xl border hover:border-blue space-y-4 group cursor-pointer'>
                                        <div className='flex space-x-4 justify-between'>
                                            <div className="text-lg font-medium text-black">{r.name}</div>
                                            
                                            <div className="text-xl font-bold text-black px-3">
                                                {r.is_relative ? `${r.value!*100}%` : `$${r.value}`}
                                            </div>
                                            
                                        </div>

                                        {r.is_default  && <span className="bg-blue text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">{t('DEFAULT')}</span>}

                                        {!r.is_default && <span className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full invisible group-hover:visible">{t('SET_AS_DEFAULT')}</span> }

                                        <p className="text-slate-500 text-xs">{r.description}</p>
                                    </div>
                                </>)
                            }
                            </div>

                        </>}

                        {currentUser.role_id !== RoleEnum.SUPER_ADMIN && <>

                            <div className='w-full text-lg font-semibold p-3 flex justify-between'>
                                {t("COMMISSIONS")}
                            </div>
                            <div className='px-3 grid grid-cols-3 gap-6'>
                            {
                                commissionRules.map(r => <>
                                    <div 
                                        className='py-4 px-6 max-w-sm mx-auto bg-white rounded-xl border space-y-4'>
                                        <div className='flex space-x-4 justify-between'>
                                            <div className="text-lg font-medium text-black">{r.name}</div>
                                            
                                            <div className="text-xl font-bold text-black px-3">
                                                {r.is_relative ? `${r.value!*100}%` : `$${r.value}`}
                                            </div>
                                            
                                        </div>

                                        {r.is_default  && <span className="bg-blue text-white text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">{t('DEFAULT')}</span>}

                                        <p className="text-slate-500 text-xs">{r.description}</p>
                                    </div>
                                </>)
                            }
                            </div>

                            </>}
                    </Form>
                </div>
            </div>
            <div className='pb-5 text-right'>
                <Button onClick={handleSubmit(onSubmit)}>
                    <span className='flex items-center font-semibold pr-3'>
                        <div className='w-8 p-1 aspect-square mr-2'><CheckIcon /></div>
                        {t('SAVE')}
                    </span>
                </Button>
            </div>
        </div>
        </>
    );
};