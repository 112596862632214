import {
  ArrowLeftIcon,
  ArrowsRightLeftIcon,
  CheckIcon,
  PaperAirplaneIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import moment from "moment";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import states from "states-us";
import { Accordion } from "../../components/Accordion";
import Button from "../../components/Button";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { LoadingMask } from "../../components/LoadingMask";
import { Modal } from "../../components/Modal";
import { SuccessErrorModal } from "../../components/SuccessErrorModal";
import { ClientExistsConfirmationModal } from "../../components/clients/ClientExistsConfirmationModal";
import { ClientInformationModal } from "../../components/clients/ClientInformationModal";
import { ClientPoliciesTable } from "../../components/clients/ClientPoliciesTable";
import { ClientPolicyModal } from "../../components/clients/ClientPolicyModal";
import { Form } from "../../components/form/Form";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { ReactiveFormPhoneInput } from "../../components/form/ReactiveFormPhoneInput";
import { ReactiveFormRadioButton } from "../../components/form/ReactiveFormRadioButton";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { ReactiveFormUploadFile } from "../../components/form/ReactiveFormUploadFile";
import { ClientsPath } from "../../constants/Routes";
import {
  ClientFormSectionEnum,
  getClientFormSections,
} from "../../enums/ClientFormSection";
import { getEmployeeTypes } from "../../enums/EmployeerType";
import { getGenders } from "../../enums/Gender";
import { getImmigrationTypes } from "../../enums/ImmigrationStatusType";
import { getMaritalStatuses } from "../../enums/MaritalStatus";
import { MessageTypeEnum } from "../../enums/MessageType";
import { getRelationships } from "../../enums/Relationship";
import { RoleEnum } from "../../enums/Role";
import { getCountries } from "../../i18n/countries";
import { newAddressInitialValues } from "../../models/Address";
import { Client, NewClientModel } from "../../models/Client";
import { ClientExists } from "../../models/ClientExists";
import { newDependentInitialValues } from "../../models/Dependant";
import { NewDocument } from "../../models/Document";
import { newIncomeSourceInitialValues } from "../../models/IncomeSource";
import { NewPolicy } from "../../models/Policies";
import { Tag } from "../../models/Tags";
import {
  checkClientPhoneNumber,
  createClient,
} from "../../state/clients/actions";
import { getFileSignedUrl } from "../../state/documents/actions";
import { AppDispatch } from "../../state/store";

const initialOpenedSections: Map<ClientFormSectionEnum, boolean> = new Map();
initialOpenedSections.set(ClientFormSectionEnum.PERSONAL_DETAILS, true);
initialOpenedSections.set(ClientFormSectionEnum.POLICIES, true);
//initialOpenedSections.set(ClientFormSectionEnum.ADDRESS, true);

export const NewClient = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openedSections, setOpenedSections] = useState<
    Map<ClientFormSectionEnum, boolean>
  >(initialOpenedSections);
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: any) => state.clients.isLoading);
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const clients = useSelector((state: any) => state.clients.clients);
  const error = useSelector((state: any) => state.clients.error);
  const tags = useSelector((state: any) => state.tags.tags);
  const users = useSelector((state: any) => state.users.users);
  const [showCreateError, setShowCreateError] = useState(false);
  const [agentTags, setAgentTags] = useState<Tag[]>([]);
  const [clientDocuments, setClientDocuments] = useState<any[]>([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [currentAgents, setCurrentAgents] = useState<any>();
  const [assignToAgent, setAssignToAgent] = useState<any>();
  const [isAssignClientModalOpen, setIsAssignClientModalOpen] = useState(false);
  const [showClientPolicyModal, setShowClientPolicyModal] = useState<{
    show: boolean;
    update: boolean;
    policy?: NewPolicy;
    index?: number;
  }>({ show: false, update: false });

  const [clientPhoneNumber, setClientPhoneNumber] = useState(null);
  const [isPhoneNumberEnter, setIsPhoneNumberEnter] = useState(false);
  const [clientPhoneAlreadyExist, setClientPhoneAlreadyExist] = useState(false);
  const [clientExistsWithPhone, setClientExistsWithPhone] =
    useState<ClientExists>();
  const [phoneNumberExist, setPhoneNumberExist] = useState<string>();
  const [showClientInformationModal, setShowClientInformationModal] = useState<{
    client?: ClientExists;
    currentUser?: any;
    show: boolean;
  }>({ show: false, client: undefined });

  const [
    showClientExistsConfirmationModal,
    setShowClientExistsConfirmationModal,
  ] = useState<{
    clientExists?: ClientExists;
    phoneExists?: string;
    show: boolean;
  }>({ show: false, clientExists: undefined });
  const [newClientContinueData, setNewClientContinueData] =
    useState<NewClientModel>();

  const [showSuccessErrorModal, setShowSuccessErrorModal] = useState<{
    show: boolean;
    messageType: number;
    message: string;
  }>({ show: false, messageType: 2, message: "" });
  const [isWRenewalConfirmationModalOpen, setIsWRenewalConfirmationModalOpen] =
    useState(false);
  let hasToSendWRenewalMessage = false;
  useEffect(() => {
    if (showCreateError) {
      handleShowSuccessErrorModal(
        MessageTypeEnum.FAILED,
        error.cause_info ?? t("SOMETHING_WENT_WRONG")
      );
    }
  }, [showCreateError, error, t]);

  const methods = useForm<NewClientModel>({
    defaultValues: {
      country_of_birth: "US",
      income_sources: [newIncomeSourceInitialValues],
      dependents: [newDependentInitialValues],
      addresses: [
        {
          address_line_1: "",
          address_line_2: "",
          city: "",
          state: "",
          zip_code: "",
          is_main_address: true,
        },
      ],
      tags_responses: [],
      attachments: [],
    },
  });
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = methods;

  const {
    fields: dependents,
    append: appendDependent,
    remove: removeDependent,
  } = useFieldArray({
    control,
    name: "dependents",
  });

  const {
    fields: addresses,
    append: appendAddress,
    remove: removeAddress,
  } = useFieldArray({
    control,
    name: "addresses",
  });

  const {
    fields: incomeSources,
    append: appendIncomeSource,
    remove: removeIncomeSource,
  } = useFieldArray({
    control,
    name: "income_sources",
  });

  const {
    fields: policies,
    append: appendPolicy,
    remove: removePolicy,
  } = useFieldArray({
    control,
    name: "policies",
  });

  const updatePolicy = (index: number, policy: NewPolicy) => {
    setValue(`policies.${index}.number`, policy.number);
    setValue(`policies.${index}.carrier_id`, policy.carrier_id);
    setValue(`policies.${index}.effective_end_date`, policy.effective_end_date);
    setValue(
      `policies.${index}.effective_start_date`,
      policy.effective_start_date
    );
    setValue(`policies.${index}.free_plan`, policy.free_plan);
    setValue(`policies.${index}.is_new`, policy.is_new);
    setValue(`policies.${index}.members_insured`, policy.members_insured);
    setValue(`policies.${index}.policy_state`, policy.policy_state);
    reset(getValues());
  };

  useEffect(() => {
    if (currentUser) {
      setValue("agent_id", currentUser.id);
    }
  }, [currentUser, setValue]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.role_id === RoleEnum.ADMIN) {
        const aTags = tags.filter(
          (tag: any) => tag.user.id === currentUser.id || !tag.is_personal
        );
        setAgentTags(aTags);

        const initalTags = tags.map((tag: any) => {
          return { tag_id: tag.id, response: "" };
        });
        setValue("tags_responses", initalTags);
      } else {
        setAgentTags(tags);

        const initalTags = tags.map((tag: any) => {
          return { tag_id: tag.id, response: "" };
        });
        setValue("tags_responses", initalTags);
      }
    }
  }, [currentUser, setValue, tags, setAgentTags]);

  const agents = useMemo(() => {
    return users.map((x: any) => {
      return {
        label: `${x.first_name} ${x.last_name}`,
        value: x.id,
      };
    });
  }, [users]);

  const getStates = useMemo(() => {
    return states.map((state) => {
      return {
        label: state.name,
        value: state.abbreviation,
        key: state.abbreviation,
      };
    });
  }, []);

  const handleClientDocumentsDrop = (e: any) => {
    for (let i = 0; i < e.length; i++) {
      setClientDocuments((prev) => [...prev, e[i]]);
    }
  };

  const handleClientDocuments = (e: any) => {
    for (let i = 0; i < e.length; i++) {
      setClientDocuments((prev) => [...prev, e[i]]);
    }
  };

  const handleRemoveClientDocument = (index: number) => {
    const list = [...clientDocuments];
    list.splice(index, 1);
    setClientDocuments(list);
  };

  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const onSubmitInvalid: SubmitErrorHandler<NewClientModel> = (data) => {
    handleShowSuccessErrorModal(
      MessageTypeEnum.FAILED,
      t("PLEASE_ENTER_ALL_CLIENT_REQUIRED_FIELDS")
    );
  };

  const onSubmit: SubmitHandler<NewClientModel> = async (
    data: NewClientModel
  ) => {
    if (clientPhoneAlreadyExist && clientExistsWithPhone != undefined) {
      setShowClientExistsConfirmationModal({
        show: true,
        clientExists: clientExistsWithPhone,
        phoneExists: phoneNumberExist,
      });
      setNewClientContinueData(data);
    } else {
      continueClientCreate(data);
    }
  };

  const continueClientCreate = async (data: NewClientModel) => {
    if (checkInvalidStartDate(data)) {
      handleShowSuccessErrorModal(
        MessageTypeEnum.FAILED,
        t("POLICY_EFFECTIVE_START_DATE_MESSAGE")
      );
    } else if (checkInvalidEndDate(data)) {
      handleShowSuccessErrorModal(
        MessageTypeEnum.FAILED,
        t("POLICY_EFFECTIVE_END_DATE_MESSAGE")
      );
    } else if (checkPolicyMembers(data)) {
      handleShowSuccessErrorModal(
        MessageTypeEnum.FAILED,
        t("POLICY_MEMBERS_MESSAGE")
      );
    } else if (checkPolicyState(data)) {
      handleShowSuccessErrorModal(
        MessageTypeEnum.FAILED,
        t("POLICY_STATE_MESSAGE")
      );
    } else if (checkClientHasNewPolicy(data)) {
      setIsWRenewalConfirmationModalOpen(true);
      setNewClientContinueData(data);
    } else {
      if (clientDocuments.length > 0) {
        uploadFiles(data);
      } else {
        createClientSubmit(data, []);
      }
    }
  };

  const uploadFiles = async (data: NewClientModel) => {
    setIsUploadingFiles(true);

    const uploadPromises = clientDocuments.map(async (file) => {
      const nameSplit = file.name.split(".");
      const filename = `${nameSplit[0]}-${Date.now()}.${nameSplit[1]}`;

      const result = await dispatch(
        getFileSignedUrl({ fileName: filename })
      ).unwrap();

      const uploadResponse = await fetch(result.upload_url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/octet-stream",
        },
        body: file,
      });

      if (uploadResponse.ok && uploadResponse.status == 200) {
        const attachment: NewDocument = {
          name: filename,
          size: `${file.size}`,
        };

        return attachment;
      }

      return undefined;
    });

    // Esperar a que todas las subidas se completen
    const results = await Promise.all(uploadPromises);

    if (results.length > 0) {
      const attachaments: NewDocument[] = [];
      results.forEach((attach) => {
        if (attach != undefined) {
          attachaments.push(attach);
        }
      });

      setIsUploadingFiles(false);
      createClientSubmit(data, attachaments);
    } else {
      setIsUploadingFiles(false);
      createClientSubmit(data, []);
    }
  };

  const createClientSubmit = async (
    data: NewClientModel,
    clientAttachments: NewDocument[]
  ) => {
    const createdData = data;

    const clientPolicies: NewPolicy[] = [];
    if (data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if (po.carrier_id != undefined) {
          clientPolicies.push({
            number: po.number,
            effective_start_date: po.effective_start_date,
            effective_end_date: po.effective_end_date,
            carrier_id: +po.carrier_id,
            members_insured: po.members_insured,
            free_plan: po.free_plan,
            policy_state: po.policy_state,
            agent_commission_rule_id: po.agent_commission_rule_id,
            agent_id: po.agent_id,
          });
        }
      });
    }
    createdData.policies = clientPolicies;
    createdData.attachments = clientAttachments;

    if (assignToAgent != null) {
      createdData.assigned_to = assignToAgent.value;
    }

    createdData.has_to_send_renewal_message = hasToSendWRenewalMessage;

    dispatch(createClient(createdData)).then((e) => {
      if (e.type === "clients/createClient/rejected") {
        setShowCreateError(true);
      } else {
        handleShowSuccessErrorModal(
          MessageTypeEnum.SUCCESS,
          t("SUCCESSFULLY_SAVED_CLIENT_INFORMATION")
        );
      }
    });
  };

  const checkInvalidStartDate = (data: NewClientModel) => {
    let policiesInvalidDates = 0;
    const todayDate = moment().format("YYYY-MM-DD");

    if (data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if (po.carrier_id != undefined) {
          if (po.effective_start_date != undefined) {
            if (moment(po.effective_start_date).isValid()) {
              if (moment(po.effective_start_date).isBefore(todayDate)) {
                policiesInvalidDates++;
              }
            } else {
              policiesInvalidDates++;
            }
          } else {
            policiesInvalidDates++;
          }
        }
      });
    }

    return policiesInvalidDates > 0;
  };

  const checkInvalidEndDate = (data: NewClientModel) => {
    let policiesInvalidDates = 0;
    const todayDate = moment().format("YYYY-MM-DD");

    if (data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        console.log(moment(po.effective_end_date));
        if (po.carrier_id != undefined) {
          if (po.effective_end_date != undefined) {
            if (moment(po.effective_end_date).isValid()) {
              if (
                moment(po.effective_end_date).isBefore(todayDate) ||
                moment(po.effective_end_date).isSameOrBefore(
                  po.effective_start_date
                )
              ) {
                policiesInvalidDates++;
              }
            } else {
              policiesInvalidDates++;
            }
          } else {
            policiesInvalidDates++;
          }
        }
      });
    }

    return policiesInvalidDates > 0;
  };

  const checkPolicyMembers = (data: NewClientModel) => {
    let policiesMembers = 0;

    if (data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if (po.carrier_id != undefined) {
          if (po.members_insured != undefined) {
            if (po.members_insured <= 0) {
              policiesMembers++;
            }
          } else {
            policiesMembers++;
          }
        }
      });
    }

    return policiesMembers > 0;
  };

  const checkPolicyState = (data: NewClientModel) => {
    let policiesState = 0;

    if (data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if (po.carrier_id != undefined) {
          if (po.policy_state == undefined || po.policy_state == null) {
            policiesState++;
          }
        }
      });
    }

    return policiesState > 0;
  };

  const getClients = useMemo(() => {
    return clients.map((client: Client) => {
      return {
        label: `${client.first_name} ${client.last_name}`,
        value: `${client.first_name} ${client.last_name}`,
        key: client.id,
      };
    });
  }, [clients]);

  const openAssignClientModal = () => {
    //setSelectedDocument(envelope);
    setIsAssignClientModalOpen(true);
  };

  const closeAssignClientModal = () => {
    setIsAssignClientModalOpen(false);
  };

  const assignClientModal = () => {
    return (
      <Modal
        isOpen={isAssignClientModalOpen}
        onClose={closeAssignClientModal}
        label={t("ASSIGN_CLIENT_TITLE")}
        saveButton={{
          label: t("ASSIGN"),
          icon: <ArrowsRightLeftIcon />,
          onClick: setAssignedClientTo,
        }}
      >
        <div className="w-full pt-3 pb-5">
          <div className={"w-full px-3"}>
            <Select
              isSearchable
              isClearable
              isLoading={agents.length === 0}
              onChange={(inputValue: any) => {
                if (inputValue == null) {
                  setAssignToAgent(null);
                }
                setCurrentAgents(inputValue);
              }}
              name="agent"
              options={agents}
              value={currentAgents}
              placeholder="Select agent"
              className="basic-single"
              classNamePrefix="select"
              maxMenuHeight={200}
              classNames={{
                control: (state) =>
                  "bg-white opacity-90 py-1 px-1.5 rounded shadow w-full min-w-[200px]",
              }}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: "grey-50",
                }),
              }}
            />
            <label className="text-gray-600 text-xs mb-5">
              {t("ASSIGN_CLIENT_MESSAGE")}
            </label>
          </div>
        </div>
      </Modal>
    );
  };

  const setAssignedClientTo = () => {
    closeAssignClientModal();
    setAssignToAgent(currentAgents);
  };

  useEffect(() => {
    async function checkPhoneNumber() {
      const phone = "1" + clientPhoneNumber;
      const result = await dispatch(
        checkClientPhoneNumber({ phone: phone })
      ).unwrap();
      if (result.length > 0) {
        const client = result[0];
        setClientPhoneAlreadyExist(true);
        setClientExistsWithPhone(client);
        setPhoneNumberExist(phone);

        toast(t("CLIENT_WITH_PHONE_NUMBER_ALREADY_EXIST"));
        window.scrollTo(0, 0);
      } else {
        setClientPhoneAlreadyExist(false);
        setClientExistsWithPhone(undefined);
        setPhoneNumberExist("");
      }

      setIsPhoneNumberEnter(false);
    }

    if (clientPhoneNumber != null && isPhoneNumberEnter) {
      checkPhoneNumber();
    }
  }, [clientPhoneNumber, isPhoneNumberEnter]);

  const handleChangePhone = (phone: any) => {
    if (phone.length == 10) {
      setClientPhoneNumber(phone);
    }
  };

  const handleBlurPhone = (e: any) => {
    setIsPhoneNumberEnter(true);
  };

  const showClientExistsInformation = () => {
    setShowClientInformationModal({
      show: true,
      client: clientExistsWithPhone,
      currentUser: currentUser,
    });
  };

  const handleCloseClientExistsModal = () => {
    setShowClientExistsConfirmationModal({
      show: false,
      clientExists: undefined,
    });
    setNewClientContinueData(undefined);
  };

  const handleContinueClientExistsModal = () => {
    setShowClientExistsConfirmationModal({
      show: false,
      clientExists: undefined,
    });
    if (newClientContinueData != undefined) {
      continueClientCreate(newClientContinueData);
    }
  };

  const handleShowSuccessErrorModal = (
    messageType: number,
    message: string
  ) => {
    setShowSuccessErrorModal({
      show: true,
      messageType: messageType,
      message: message,
    });
  };

  const handleCloseSuccessErrorModal = () => {
    if (showSuccessErrorModal.messageType === MessageTypeEnum.SUCCESS) {
      setShowSuccessErrorModal({
        show: false,
        messageType: MessageTypeEnum.FAILED,
        message: "",
      });
      navigate(ClientsPath);
    } else if (showSuccessErrorModal.messageType === MessageTypeEnum.FAILED) {
      setShowSuccessErrorModal({
        show: false,
        messageType: MessageTypeEnum.FAILED,
        message: "",
      });
      setShowCreateError(false);
    }
  };

  const showPolicyMessageConfirmationModal = () => {
    return (
      <ConfirmationModal
        isOpen={isWRenewalConfirmationModalOpen}
        onClose={cancelWelcomeRenewalMessage}
        label={t("WELCOME_RENEWAL_MESSAGE")}
        cancelButton={{
          label: t("NO"),
          icon: <XMarkIcon />,
          onClick: cancelWelcomeRenewalMessage,
        }}
        saveButton={{
          label: t("SEND"),
          icon: <PaperAirplaneIcon />,
          onClick: sendWelcomeRenewalMessage,
        }}
      >
        {/*isConfirmationLoading && <LoadingMask />*/}
        <div className="w-full flex flex-wrap items-center justify-center">
          <label className="text-lg font-medium py-4">
            {t("SEND_WELCOME_RENEWAL_MESSAGE")}
          </label>
        </div>
      </ConfirmationModal>
    );
  };

  const cancelWelcomeRenewalMessage = () => {
    setIsWRenewalConfirmationModalOpen(false);
    //setHasToSendWRenewalMessage(false);
    hasToSendWRenewalMessage = false;
    if (newClientContinueData != undefined) {
      if (clientDocuments.length > 0) {
        uploadFiles(newClientContinueData);
      } else {
        createClientSubmit(newClientContinueData, []);
      }
    }
  };

  const sendWelcomeRenewalMessage = () => {
    setIsWRenewalConfirmationModalOpen(false);
    //setHasToSendWRenewalMessage(true);
    hasToSendWRenewalMessage = false;
    if (newClientContinueData != undefined) {
      if (clientDocuments.length > 0) {
        uploadFiles(newClientContinueData);
      } else {
        createClientSubmit(newClientContinueData, []);
      }
    }
  };

  const checkClientHasNewPolicy = (data: NewClientModel) => {
    let newPolicies = 0;

    if (data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if (po.carrier_id != undefined || po.is_new) {
          newPolicies++;
        }
      });
    }

    return newPolicies > 0;
  };

  const PersonalDetailsSection = useMemo(() => {
    return (
      <div className="w-full flex flex-wrap">
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("FIRST_NAME")}
          name="first_name"
          isRequired
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("MIDDLE_NAME")}
          name="middle_name"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("LAST_NAME")}
          name="last_name"
          isRequired
        />
        <ReactiveFormInput
          control={control}
          type="date"
          className="md:w-1/4"
          label={t("DATE_OF_BIRTH")}
          name="date_of_birth"
          isRequired
        />
        <ReactiveFormSelect
          className="md:w-1/4"
          name="country_of_birth"
          label={t("COUNTRY_OF_BIRTH")}
          options={getCountries()}
          control={control}
          isRequired
        />
        <ReactiveFormSelect
          className="md:w-1/4"
          name="gender_id"
          label={t("GENDER")}
          options={getGenders()}
          control={control}
        />
        <ReactiveFormSelect
          className="md:w-1/4"
          name="marital_status_id"
          label={t("MARITAL_STATUS")}
          options={getMaritalStatuses()}
          control={control}
        />
        <ReactiveFormPhoneInput
          control={control}
          className="md:w-1/4"
          name="phone"
          label={t("PHONE")}
          handleOnChange={handleChangePhone}
          handleOnBlur={handleBlurPhone}
          isRequired
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("EMAIL")}
          name="email"
          type="email"
          isRequired
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("HEIGHT")}
          name="height"
          type="number"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("WEIGHT")}
          name="weight"
          type="number"
        />
        <ReactiveFormSelect
          control={control}
          className={"w-full px-3 md:w-1/3"}
          name="referred_by"
          label={t("REFERENCE")}
          options={getClients}
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("DOCTOR_FULL_NAME")}
          name="doctor_full_name"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("DOCTOR_CITY")}
          name="doctor_city"
        />
        <ReactiveFormRadioButton
          className={"w-full px-3 md:w-1/3"}
          name="tobacco"
          label={t("4_OR_MORE_TOBACCO")}
          control={control}
          options={[
            { label: t("YES"), value: "1" },
            { label: t("NO"), value: "0" },
          ]}
        />
        <ReactiveFormRadioButton
          className={"w-full px-3 md:w-1/3"}
          name="po_box"
          label={t("PO_BOX")}
          control={control}
          options={[
            { label: t("YES"), value: "1" },
            { label: t("NO"), value: "0" },
          ]}
        />
        <ReactiveFormRadioButton
          className={"w-full px-3 md:w-1/3"}
          name="file_tax_return"
          label={t("WILL_YOU_FILE_TAX_RETURN")}
          control={control}
          options={[
            { label: t("YES"), value: "1" },
            { label: t("NO"), value: "0" },
          ]}
        />
        {agentTags.length > 0 && (
          <div className="w-full">
            <div className="text-lg font-semibold py-3 px-3 w-full">
              {t("ADDITIONAL_INFORMATION")}
            </div>
            <div className="w-full flex flex-wrap gap-y-4">
              {agentTags.map((tag, index) => {
                return (
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/3"
                    label={tag.label}
                    name={`tags_responses.${index}.response`}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }, [control, getClients, t]);

  const AddressesSection = useMemo(() => {
    return (
      <div className="w-full flex flex-wrap gap-y-4">
        {addresses
          ?.sort((x) => Number(x.is_main_address))
          .map((source, index) => {
            return (
              <Fragment key={source.id}>
                <div
                  className={classNames({
                    "w-full flex flex-wrap": true,
                    "border-b pb-5 mb-4 border-slate-300":
                      index + 1 !== addresses.length,
                  })}
                >
                  {source.is_main_address && (
                    <div className="text-lg font-semibold pb-3 px-3 w-full">
                      {t("MAIN_ADDRESS")}
                    </div>
                  )}
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/2"
                    label={t("STREET_ADDRESS")}
                    name={`addresses.${index}.address_line_1`}
                  />
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/2"
                    label={t("STREET_ADDRESS2")}
                    name={`addresses.${index}.address_line_2`}
                  />
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/3"
                    label={t("CITY")}
                    name={`addresses.${index}.city`}
                  />
                  <ReactiveFormSelect
                    className="md:w-1/3"
                    name={`addresses.${index}.state`}
                    label={t("STATE")}
                    options={getStates}
                    control={control}
                  />

                  <div className="flex md:w-1/3">
                    <ReactiveFormInput
                      control={control}
                      className="mr-2"
                      label={t("ZIP_CODE")}
                      name={`addresses.${index}.zip_code`}
                    />
                    <div className="self-end mb-3 mr-3">
                      {+index === 0 ? (
                        <Button
                          iconOnly
                          classNames="h-10 w-10"
                          onClick={() => appendAddress(newAddressInitialValues)}
                        >
                          <PlusIcon className="h-4 w-4 ml-1" />
                        </Button>
                      ) : (
                        <Button
                          iconOnly
                          classNames="h-10 w-10"
                          onClick={() => removeAddress(index)}
                        >
                          <TrashIcon className="h-4 w-4 ml-1" />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          })}
      </div>
    );
  }, [addresses, t, control, getStates, appendAddress, removeAddress]);

  const ImmigrationStatusSection = useMemo(() => {
    return (
      <div className="w-full flex flex-wrap">
        <ReactiveFormSelect
          className="md:w-1/3"
          name="immigration_status.immigration_status_type_id"
          label={t("IMMIGRATION_STATUS_TYPE")}
          options={getImmigrationTypes()}
          control={control}
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("PASSPORT_NUMBER")}
          name="immigration_status.passport_number"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("USCIS_NUMBER")}
          name="immigration_status.uscis_number"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("SOCIAL_SECURITY_NUMBER")}
          name="immigration_status.social_security_number"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("SSN_ISSUE_DATE")}
          name="immigration_status.ssn_issue_date"
          type="date"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("GREEN_CARD_NUMBER")}
          name="immigration_status.green_card_number"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("GC_EXPIRATION_DATE")}
          name="immigration_status.gc_expiration_date"
          type="date"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("WORK_PERMIT_CARD")}
          name="immigration_status.work_permit_card"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("WPC_EXPIRATION_DATE")}
          name="immigration_status.wpc_expiration_date"
          type="date"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("DRIVER_LICENSE")}
          name="immigration_status.driver_license"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("DL_EXPIRATION_DATE")}
          name="immigration_status.dl_expiration_date"
          type="date"
        />
      </div>
    );
  }, [control, t]);

  const IncomeSourcesSection = useMemo(() => {
    return (
      <div className="w-full flex flex-wrap">
        {incomeSources?.map((source, index) => {
          return (
            <Fragment key={source.id}>
              <ReactiveFormSelect
                className="md:w-1/5"
                name={`income_sources.${index}.employee_type_id`}
                label={t("EMPLOYEE_TYPE")}
                options={getEmployeeTypes()}
                control={control}
              />
              <ReactiveFormInput
                control={control}
                className="md:w-1/5"
                label={t("EMPLOYER_NAME")}
                name={`income_sources.${index}.employer_name`}
              />
              <ReactiveFormPhoneInput
                control={control}
                className="md:w-1/5"
                label={t("PHONE")}
                name={`income_sources.${index}.phone_number`}
              />
              <ReactiveFormInput
                control={control}
                className="md:w-1/5"
                label={t("OCCUPATION")}
                name={`income_sources.${index}.occupation`}
              />
              <div className="flex md:w-1/5">
                <ReactiveFormInput
                  control={control}
                  type="number"
                  className="mr-2"
                  label={t("INCOME")}
                  name={`income_sources.${index}.income`}
                />
                <div className="self-end mb-3">
                  {+index === 0 ? (
                    <Button
                      iconOnly
                      classNames="h-10 w-10"
                      onClick={() =>
                        appendIncomeSource(newIncomeSourceInitialValues)
                      }
                    >
                      <PlusIcon className="h-4 w-4 ml-1" />
                    </Button>
                  ) : (
                    <Button
                      iconOnly
                      classNames="h-10 w-10"
                      onClick={() => removeIncomeSource(index)}
                    >
                      <TrashIcon className="h-4 w-4 ml-1" />
                    </Button>
                  )}
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  }, [incomeSources, t, control, appendIncomeSource, removeIncomeSource]);

  const PoliciesSection = useMemo(() => {
    return (
      <div className="w-full">
        <ClientPoliciesTable
          policies={policies}
          addPolicy={() =>
            setShowClientPolicyModal({ show: true, update: false })
          }
          removePolicy={(index) => removePolicy(index)}
          updatePolicy={(index, policy) =>
            setShowClientPolicyModal({
              show: true,
              update: true,
              policy: policy,
              index: index,
            })
          }
        />
      </div>
    );
  }, [policies, removePolicy]);

  const DependentsSection = useMemo(() => {
    return (
      <div className="w-full flex flex-wrap gap-y-4">
        {dependents?.map((source, index) => {
          return (
            <Fragment key={source.id}>
              <div
                className={classNames({
                  "w-full flex flex-wrap": true,
                  "border-b pb-5 mb-5 border-slate-300":
                    index + 1 !== dependents.length,
                })}
              >
                <ReactiveFormInput
                  control={control}
                  className="md:w-1/3"
                  label={t("FULL_NAME")}
                  name={`dependents.${index}.name`}
                />
                <ReactiveFormInput
                  control={control}
                  className="md:w-1/3"
                  label={t("SOCIAL_SECURITY_NUMBER")}
                  name={`dependents.${index}.social_security_number`}
                />
                <ReactiveFormInput
                  control={control}
                  type="email"
                  className="md:w-1/3"
                  label={t("EMAIL")}
                  name={`dependents.${index}.email`}
                />
                <ReactiveFormPhoneInput
                  control={control}
                  className="md:w-1/4"
                  label={t("PHONE")}
                  name={`dependents.${index}.phone`}
                />
                <ReactiveFormInput
                  control={control}
                  type="date"
                  className="md:w-1/4"
                  label={t("DATE_OF_BIRTH")}
                  name={`dependents.${index}.date_of_birth`}
                />
                <ReactiveFormSelect
                  className="md:w-1/4"
                  name={`dependents.${index}.relationship_id`}
                  label={t("RELATIONSHIP")}
                  options={getRelationships()}
                  control={control}
                />

                <div className="flex md:w-1/4">
                  <ReactiveFormSelect
                    className="mr-2"
                    name={`dependents.${index}.gender_id`}
                    label={t("GENDER")}
                    options={getGenders()}
                    control={control}
                  />
                  <div className="self-end mb-3 mr-3">
                    {+index === 0 ? (
                      <Button
                        iconOnly
                        classNames="h-10 w-10"
                        onClick={() =>
                          appendDependent(newDependentInitialValues)
                        }
                      >
                        <PlusIcon className="h-4 w-4 ml-1" />
                      </Button>
                    ) : (
                      <Button
                        iconOnly
                        classNames="h-10 w-10"
                        onClick={() => removeDependent(index)}
                      >
                        <TrashIcon className="h-4 w-4 ml-1" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  }, [dependents, control, t, appendDependent, removeDependent]);

  const DocumentsSection = useMemo(() => {
    return (
      <div className="w-full">
        <div className="pb-5">
          <ReactiveFormUploadFile
            control={control}
            className="w-full"
            label={t("UPLOAD_DOCUMENTS")}
            name="client_documents"
            handleOnChange={handleClientDocuments}
            handleOnDrop={handleClientDocumentsDrop}
          />
        </div>
        {clientDocuments.map((attach, index) => {
          return (
            <Fragment key={attach.name}>
              <div className="w-full px-4 mb-3">
                <div className="flex justify-between">
                  <div className="self-center">
                    <label className="text-sm self-center">{attach.name}</label>
                    <label className="text-gray-600 text-sm self-center pl-3">
                      {formatBytes(attach.size)}
                    </label>
                  </div>
                  <Button
                    iconOnly
                    classNames="h-7 w-7 self-end"
                    onClick={() => handleRemoveClientDocument(index)}
                  >
                    <TrashIcon className="h-3 w-3" />
                  </Button>
                </div>
                <hr className="mt-3"></hr>
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  }, [
    handleClientDocuments,
    handleClientDocumentsDrop,
    handleRemoveClientDocument,
    t,
  ]);

  const getSectionInfo = useCallback(
    (key: ClientFormSectionEnum) => {
      switch (key) {
        case ClientFormSectionEnum.PERSONAL_DETAILS:
          return PersonalDetailsSection;
        case ClientFormSectionEnum.ADDRESS:
          return AddressesSection;
        case ClientFormSectionEnum.IMMIGRATION_STATUS:
          return ImmigrationStatusSection;
        case ClientFormSectionEnum.INCOME_SOURCES:
          return IncomeSourcesSection;
        case ClientFormSectionEnum.POLICIES:
          return PoliciesSection;
        case ClientFormSectionEnum.DEPENDENTS:
          return DependentsSection;
        case ClientFormSectionEnum.DOCUMENTS:
          return DocumentsSection;
      }
    },
    [
      PersonalDetailsSection,
      AddressesSection,
      ImmigrationStatusSection,
      IncomeSourcesSection,
      PoliciesSection,
      DependentsSection,
      DocumentsSection,
    ]
  );

  const getBlocks = useMemo(() => {
    return getClientFormSections()
      .filter(
        (x) =>
          x.key !== ClientFormSectionEnum.CONSENT_DOCUMENTS &&
          x.key !== ClientFormSectionEnum.NOTES
      )
      .map((section) => {
        const key = section.key as ClientFormSectionEnum;
        const currentSection = openedSections.get(key);
        const updatedSections = new Map(openedSections); // Create a new Map
        return {
          label: section.label,
          key: section.key,
          isOpen: currentSection,
          handleChange: () => {
            updatedSections.set(key, !currentSection); // Update the new Map
            setOpenedSections(updatedSections); // Set the new Map
          },
          info: getSectionInfo(key),
        };
      });
  }, [openedSections, getSectionInfo]);

  return (
    <div>
      {(isLoading || isUploadingFiles) && <LoadingMask />}
      {assignClientModal()}
      {showPolicyMessageConfirmationModal()}
      <ClientPolicyModal
        info={showClientPolicyModal}
        onClose={() => setShowClientPolicyModal({ show: false, update: false })}
        addPolicy={(policy: NewPolicy) => appendPolicy(policy)}
        updatePolicy={(index: number, policy: NewPolicy) =>
          updatePolicy(index, policy)
        }
      />
      <ClientInformationModal
        info={showClientInformationModal}
        onClose={() =>
          setShowClientInformationModal({ show: false, client: undefined })
        }
      />
      <ClientExistsConfirmationModal
        info={showClientExistsConfirmationModal}
        onClose={handleCloseClientExistsModal}
        onContinueCreate={handleContinueClientExistsModal}
      />
      <SuccessErrorModal
        info={showSuccessErrorModal}
        onClose={handleCloseSuccessErrorModal}
      />
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <Form handleOnSubmit={handleSubmit(onSubmit, onSubmitInvalid)}>
        {clientPhoneAlreadyExist && (
          <div className="w-full grow px-5 pt-5 flex flex-col">
            <div className="p-5 bg-amber-400 rounded-lg shadow grid justify-items-center">
              <div className="text-white text-lg text-center font-medium">
                {`${t("A_CLIENT_WITH_PHONE")}`}{" "}
                <label
                  className="underline cursor-pointer"
                  onClick={() => showClientExistsInformation()}
                >{`+${phoneNumberExist}`}</label>{" "}
                {`${t("IS_BEING_CONTACTED_BY", {
                  agentName: clientExistsWithPhone?.agent.name,
                  agentLastName: clientExistsWithPhone?.agent.last_name,
                })}`}
              </div>
            </div>
          </div>
        )}
        <Accordion blocks={getBlocks} />
        <div className="px-5 pb-5 justify-between w-full flex items-center">
          <Button isTerciary onClick={() => navigate(ClientsPath)}>
            <span className="flex items-center font-semibold pr-3">
              <div className="w-8 p-1 aspect-square mr-2">
                <ArrowLeftIcon />
              </div>
              {t("RETURN")}
            </span>
          </Button>
          <div className="flex flex-row items-center">
            <div className="flex flex-col mr-5">
              <label className="grid justify-items-end text-gray-600">{`${t(
                "AGENT_OF_RECORD"
              )}:`}</label>
              <label
                className="grid justify-items-end underline cursor-pointer text-lg text-sky-600"
                onClick={openAssignClientModal}
              >
                {assignToAgent != null
                  ? `${assignToAgent?.label ?? ""}`
                  : `${currentUser?.first_name ?? ""} ${
                      currentUser?.last_name ?? ""
                    }`}
              </label>
            </div>
            <Button onClick={handleSubmit(onSubmit, onSubmitInvalid)}>
              <span className="flex items-center font-semibold pr-3">
                <div className="w-8 p-1 aspect-square mr-2">
                  <CheckIcon />
                </div>
                {t("SAVE")}
              </span>
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};
