import { ArrowPathIcon, ChartBarIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Button";
import Chart from "../../components/Chart";
import { LoadingMask } from "../../components/LoadingMask";
import { FormSelect } from "../../components/form/FormSelect";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import {
  fetchNewClientFromLeads,
  fetchNewLeads,
  fetchUnContactedAnsweredLeads,
} from "../../state/dashboard/actions";
import { AppDispatch, RootState } from "../../state/store";
import { DashboardData } from "../../components/DashboardData";
import { ChartData } from "../../models/ChartData";
import { MembersSellsWidget } from "../../components/dashboard/MembersSellsWidget";

interface DateRange {
  unit: moment.unitOfTime.DurationConstructor;
  value: number;
}

interface ChartParams {
  startDate: string;
  endDate: string;
}

const dateRanges: Record<string, DateRange> = {
  "1": { unit: "days", value: 1 },
  "2": { unit: "days", value: 3 },
  "3": { unit: "weeks", value: 1 },
  "4": { unit: "weeks", value: 3 },
  "5": { unit: "months", value: 1 },
  "6": { unit: "months", value: 3 },
  "7": { unit: "months", value: 6 },
};

export const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector(
    (state: RootState) => state.users.currentUser
  );
  const {
    unAnsweredLeads,
    unContactedLeads,
    newClientLeads,
    newLeads,
    isNewClientLoaded,
    isNewClientLoading,
    isNewLeadLoading,
    isNewLeadLoaded,
    isUnContactedAnsweredLeadLoading,
    isUnContactedAnsweredLeadLoaded, 
    isAgentTotalMembersLoading,
    isAgentTotalMembersLoaded 
  } = useSelector((state: RootState) => state.dashboard);
  const [selectedDateChart, setSelectedDateChat] = useState<string>("7");
  const [selectedOtherDate, setSelectedOtherDate] = useState(false);
  const [loadingNewLeads, setLoadingNewLeads] = useState(false);
  const [loadingNewClients, setLoadingNewClients] = useState(false);
  const [loadingContactedLeads, setLoadingContactedLeads] = useState(false);

  const methods = useForm({});
  const { handleSubmit, control, setValue } = methods;

  useEffect(() => {
    if (currentUser) {
      const endDate: Date = moment().endOf("day").toDate();
      let startDate: Date | null = null;
      let otherDate = false;

      if (dateRanges[selectedDateChart]) {
        const { unit, value } = dateRanges[selectedDateChart];
        startDate = moment(endDate)
          .subtract(value, unit)
          .startOf("day")
          .toDate();
        setValue("start_date", undefined);
        setValue("end_date", undefined);
      } else {
        otherDate = true;
        setValue("start_date", null);
        setValue("end_date", null);
      }

      setSelectedOtherDate(otherDate);

      if (startDate && endDate) {
        const formattedStart = moment(startDate).format("YYYY-MM-DD");
        const formattedEnd = moment(endDate).format("YYYY-MM-DD");
        const params = { startDate: formattedStart, endDate: formattedEnd };

        dispatch(fetchNewLeads(params));
        dispatch(fetchNewClientFromLeads(params));
        dispatch(fetchUnContactedAnsweredLeads(params));
      }
    }
  }, [
    currentUser,
    dispatch,
    selectedDateChart,
    setValue,
    setSelectedOtherDate,
  ]);

  const isLoadingCharts = useMemo(() => {
    return (
      (isNewLeadLoading ||
        isNewClientLoading ||
        isUnContactedAnsweredLeadLoading ||
        isAgentTotalMembersLoading) &&
      !isNewLeadLoaded &&
      !isNewClientLoaded &&
      !isUnContactedAnsweredLeadLoaded &&
      !isAgentTotalMembersLoaded
    );
  }, [
    isNewLeadLoading,
    isNewClientLoading,
    isUnContactedAnsweredLeadLoading,
    isNewLeadLoaded,
    isNewClientLoaded,
    isUnContactedAnsweredLeadLoaded,
    isAgentTotalMembersLoading,
    isAgentTotalMembersLoaded
  ]);

  const isRefreshingCharts = useMemo(() => {
    return loadingNewLeads || loadingNewClients || loadingContactedLeads;
  }, [loadingNewLeads, loadingNewClients, loadingContactedLeads]);

  const reloadCharts = async (params: ChartParams) => {
    dispatch(fetchNewLeads(params)).then(() => {
      setLoadingNewLeads(false);
    });

    dispatch(fetchNewClientFromLeads(params)).then(() => {
      setLoadingNewClients(false);
    });

    dispatch(fetchUnContactedAnsweredLeads(params)).then(() => {
      setLoadingContactedLeads(false);
    });
  };

  const getDatesOptions = () => {
    return [
      {
        key: 1,
        value: "1",
        label: t("1_DAY"),
      },
      {
        key: 2,
        value: "2",
        label: t("3_DAYS"),
      },
      {
        key: 3,
        value: "3",
        label: t("1_WEEK"),
      },
      {
        key: 4,
        value: "4",
        label: t("3_WEEKS"),
      },
      {
        key: 5,
        value: "5",
        label: t("1_MONTH"),
      },
      {
        key: 6,
        value: "6",
        label: t("3_MONTHS"),
      },
      {
        key: 7,
        value: "7",
        label: t("6_MONTHS"),
      },
      {
        key: 8,
        value: "8",
        label: t("OTHER"),
      },
    ];
  };

  const handleLastDateChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedDateChat(event.target.value);

    let startDate: Date | null = null;
    let endDate: Date | null = moment("2023-11-23").endOf("day").toDate();

    const selectedRange = dateRanges[event.target.value];

    if (selectedRange) {
      setSelectedOtherDate(false);
      setValue("start_date", undefined);
      setValue("end_date", undefined);

      setLoadingNewLeads(true);
      setLoadingNewClients(true);
      setLoadingContactedLeads(true);

      startDate = moment(endDate)
        .subtract(selectedRange.value, selectedRange.unit)
        .startOf("day")
        .toDate();
    } else {
      setSelectedOtherDate(true);
      startDate = null;
      endDate = null;
    }

    if (startDate && endDate) {
      const formattedStart = moment(startDate).format("YYYY-MM-DD");
      const formattedEnd = moment(endDate).format("YYYY-MM-DD");

      const params = { startDate: formattedStart, endDate: formattedEnd };
      reloadCharts(params);
    }
  };

  const onSubmitOtherDate = (data: FieldValues) => {
    setLoadingNewLeads(true);
    setLoadingNewClients(true);
    setLoadingContactedLeads(true);

    const startDate = moment(data["start_date"])
      .hours(0)
      .minutes(0)
      .seconds(0)
      .format("YYYY-MM-DD");
    const endDate = moment(data["end_date"])
      .hours(23)
      .minutes(59)
      .seconds(59)
      .format("YYYY-MM-DD");

    const params = { startDate: startDate, endDate: endDate };
    reloadCharts(params);
  };

  const newLeadsTotal = useMemo(
    () =>
      newLeads.reduce((sum: number, lead: ChartData) => sum + lead.value, 0),
    [newLeads]
  );

  const uncontactedLeadsTotal = useMemo(
    () =>
      unContactedLeads.reduce(
        (sum: number, lead: ChartData) => sum + lead.value,
        0
      ),
    [unContactedLeads]
  );

  const unAnsweredLeadsTotal = useMemo(
    () =>
      unAnsweredLeads.reduce(
        (sum: number, lead: ChartData) => sum + lead.value,
        0
      ),
    [unAnsweredLeads]
  );

  const newClientsTotal = useMemo(
    () =>
      newClientLeads.reduce(
        (sum: number, lead: ChartData) => sum + lead.value,
        0
      ),
    [newClientLeads]
  );

  return (
    <>
      {(isLoadingCharts || isRefreshingCharts) && <LoadingMask />}
      <div className="flex flex-col w-full p-10 gap-10">
        <div className="flex flex-col w-full gap-6">
          <div className="w-full flex flex-wrap justify-between bg-white rounded-lg">
            <div className="flex">
              <DashboardData title="New Leads" value={newLeadsTotal} />
              <hr className="border-t border-gray-300 my-4"></hr>
              <DashboardData
                title="Uncontacted Leads"
                value={uncontactedLeadsTotal}
              />
              <DashboardData
                title="Unanswered Leads"
                value={unAnsweredLeadsTotal}
              />
              <DashboardData
                title="New Clients"
                value={newClientsTotal}
                isLightBlue={true}
              />
            </div>
            <FormSelect
              className="md:w-1/4 mr-2"
              name="last_date"
              label={""}
              options={getDatesOptions()}
              selectedValue={selectedDateChart ?? "6"}
              handleOnChange={handleLastDateChange}
            />
          </div>
          {selectedOtherDate && (
            <div className="w-full flex flex-wrap p-2 bg-white rounded-lg">
              <ReactiveFormInput
                control={control}
                type="date"
                className="md:w-2/5"
                label={t("START_DATE")}
                name="start_date"
                isRequired
              />
              <ReactiveFormInput
                control={control}
                type="date"
                className="md:w-2/5"
                label={t("END_DATE")}
                name="end_date"
                isRequired
              />
              <div className="md:w-1/5 flex self-end justify-center pb-3 pr-2 text-right">
                <Button onClick={handleSubmit(onSubmitOtherDate)} fullWidth>
                  <span className="flex items-center font-semibold pr-3">
                    <div className="w-8 p-1 aspect-square mr-2">
                      <ChartBarIcon />
                    </div>
                    {t("GENERATE")}
                  </span>
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className="w-full grow grid grid-cols-1 md:grid-cols-2 gap-10">
          <MembersSellsWidget />
          <Chart
            data={newLeads}
            title={t("NEW_LEADS_CHART")}
            subtitle={t("NEW_LEADS_CHART_MESSAGE")}
          />
          <Chart
            data={unContactedLeads}
            title={t("UN_CONTACTED_LEADS_CHART")}
            subtitle={t("UN_CONTACTED_LEADS_CHART_MESSAGE")}
          />
          <Chart
            data={unAnsweredLeads}
            title={t("UN_ANSWERED_LEADS_CHART")}
            subtitle={t("UN_ANSWERED_LEADS_CHART_MESSAGE")}
          />
          <Chart
            data={newClientLeads}
            isLightBlue={true}
            title={t("NEW_CLIENT_LEADS_CHART")}
            subtitle={t("NEW_CLIENT_LEADS_CHART_MESSAGE")}
          />
        </div>
      </div>
    </>
  );
};
