import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { axiosClient } from "../axiosClient";
import { AgentMembers } from "../../models/Dashboard";

export const fetchNewLeads = createAsyncThunk("dashboard/newLeads", async (params: {startDate: string, endDate: string}) => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/dashboard/new-leads`, {params: {startDate: params.startDate, endDate: params.endDate}});
    return response.data
});

export const fetchNewClientFromLeads = createAsyncThunk("dashboard/newClientLeads", async (params: {startDate: string, endDate: string}) => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/dashboard/new-client-leads`, {params: {startDate: params.startDate, endDate: params.endDate}});
    return response.data
});

export const fetchUnContactedAnsweredLeads = createAsyncThunk("dashboard/unContactedAnsweredLeads", async (params: {startDate: string, endDate: string}) => {
    const response: AxiosResponse<any[]> = await (await axiosClient()).get(`/dashboard/un-contacted-answered-leads`, {params: {startDate: params.startDate, endDate: params.endDate}});
    return response.data
});

export const fetchAgentTotalMembers = createAsyncThunk("dashboard/agentTotalMembers", async (params: {startDate?: string, endDate?: string, timeZone?: string, sellsFliter: number}) => {
    const response: AxiosResponse<any> = await (await axiosClient()).get(`/dashboard/agent-total-members`, {params: {startDate: params.startDate, endDate: params.endDate, timeZone: params.timeZone, sellsFilter: params.sellsFliter}});
    return {
        agentMembers: response.data,
        sellFilter: params.sellsFliter
    }
});