import classNames from 'classnames';
import { PropsWithChildren, useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
const Layout = (props: PropsWithChildren) => {
  const [collapsed, setSidebarCollapsed] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  return (
    <div
      className={classNames({
        'grid bg-zinc-100 min-h-screen w-full': true,
        'grid-cols-sidebar': !collapsed,
        'grid-cols-sidebar-collapsed': collapsed,
        'transition-[grid-template-columns] duration-300 ease-in-out': true,
      })}
    >
      <Sidebar
        collapsed={collapsed}
        setCollapsed={setSidebarCollapsed}
        shown={showSidebar}
      />
      <div className="flex flex-col w-screen md:w-auto bg-zinc-100">
        <Navbar onMenuButtonClick={() => setShowSidebar((prev) => !prev)} />
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
