import { PencilIcon, PlusCircleIcon, TrashIcon } from "@heroicons/react/24/solid";
import { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import Button from "../../components/Button";
import FilteredTable from "../../components/FilteredTable";
import { LoadingMask } from "../../components/LoadingMask";
import { Modal } from "../../components/Modal";
import { Toast } from "../../components/Toast";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { ReactiveFormRadioButtonSelect } from "../../components/form/ReactiveFormRadioButtonSelect";
import { RoleEnum } from "../../enums/Role";
import { NewTagData, Tag } from "../../models/Tags";
import { AppDispatch } from '../../state/store';
import { createTag, deleteTag, updateTag } from "../../state/tags/action";

export const Tags = () => {
    const { t } = useTranslation()
    const tags = useSelector((state: any) => state.tags.tags);
    const isLoading = useSelector((state: any) => state.tags.isLoading);
    const error = useSelector((state: any) => state.tags.error);
    const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
    const [isCreatingTag, setIsCreatingTag] = useState(false);
    const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
    const [isUpdatingTag, setIsUpdatingTag] = useState(false);
    const [selectedTag, setSelectedTag] = useState<Tag | null>(null);
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: any) => state.users.currentUser);

    const createMethods = useForm<any>({
        defaultValues: {}
      });
    const { handleSubmit: handleCreateSubmit, control: createControl } = createMethods;

    const updateMethods = useForm<any>({
        defaultValues: {}
      });
    const { handleSubmit: handleUpdateSubmit, control: updateControl, setValue: setUpdateValue } = updateMethods;

    const columns = useMemo(
        () => [

            {
                Header: t('LABEL'),
                accessor: "label",
            },
            {
                Header: t('CREATED_BY'),
                accessor: "user.name",
            },
            {
                Header: t('TAG_TYPE'),
                accessor: "tag_type",
            },
            {
                Header: " ",
                Cell: (row: any) => {
                    return <td className="flex flex-row justify-center divide-x">
                        <Button
                            isTerciary
                            isDisabled={row.row.original.user.id !== currentUser.id}
                            iconOnly
                            tooltip={t("UPDATE_TAG")}
                            onClick={() => openUpdateTagModal(row.row.original)} classNames="mr-2"><PencilIcon className="h-5 aspect-square text-blue" />
                        </Button>
                        <Button
                            isTerciary
                            isDisabled={row.row.original.user.id !== currentUser.id}
                            tooltip={t("DELETE_TAG")}
                            iconOnly
                            onClick={() => onDeleteSubmit(row.row.original)} classNames="pl-2"><TrashIcon className="h-5 aspect-square text-red-600" />
                        </Button>
                    </td>
                }
            }
        ],
        [t, currentUser]
    );

    const openCreateTagModal = () => {
        setIsOpenCreateModal(true);
      };
    
    const closeCreateTagModal = () => {
        setIsOpenCreateModal(false);
    };

    const showCreateTagModal = () => {
        return <Modal isOpen={isOpenCreateModal} onClose={closeCreateTagModal} label={t("NEW_TAG")} onSave={handleCreateSubmit(onCreateSubmit)} >
            {isCreatingTag && <LoadingMask />}
            <div className='w-full flex flex-wrap'>
                {
                    currentUser?.role_id === RoleEnum.ADMIN &&
                    <div className="w-full flex flex-wrap">
                        <ReactiveFormInput
                            control={createControl}
                            className='md:w-1/2'
                            label={t("LABEL")}
                            name="label"
                            isRequired />
                        <ReactiveFormRadioButtonSelect
                            className={"w-full px-3 md:w-1/2"}
                            control={createControl}
                            name="is_personal"
                            label={t("IS_PERSONAL_TAG")}
                            options={[{ label: t("YES"), value: "1" }, { label: t("NO"), value: "0" }]}
                        />
                    </div>
                }
                {
                    currentUser?.role_id === RoleEnum.AGENT &&
                    <div className="w-full">
                        <ReactiveFormInput
                            control={createControl}
                            className='md:w-1/1'
                            label={t("LABEL")}
                            name="label"
                            isRequired />
                    </div>
                }
            </div>
        </Modal>
    }

    const onCreateSubmit: SubmitHandler<NewTagData> = async (data: any) => {
        setIsCreatingTag(true);
        
        let isPersonal: boolean = false;
        if(currentUser.role_id === RoleEnum.AGENT) {
            isPersonal = true;
        } else {
            isPersonal = data.is_personal === "1" ? true : false;
        }

        const newTag: NewTagData = {
            label: data.label,
            is_personal: isPersonal
        }

        dispatch(createTag({ data: newTag, agencyId: currentUser.agency_id})).then((e) => {
          if (e.type === "tags/createTag/rejected") {
            Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info);
            setIsCreatingTag(false);
          } else {
            toast(t("SAVED_SUCCESFULLY"));
            closeCreateTagModal();
            setIsCreatingTag(false);
          }
        })
      };

    const openUpdateTagModal = (row: any) => {
        if(row.user.id === currentUser.id) {
            setUpdateValue("label", row.label);
            setUpdateValue("is_personal", `${row.is_personal}`);
            setSelectedTag(row);
            setIsOpenUpdateModal(true);
        }
    };
    
    const closeUpdateTagModal = () => {
        setIsOpenUpdateModal(false);
    };

    const showUpdateTagModal = () => {
        return <Modal isOpen={isOpenUpdateModal} onClose={closeUpdateTagModal} label={t("UPDATE_TAG")} onSave={handleUpdateSubmit(onUpdateSubmit)}>
            {isUpdatingTag && <LoadingMask />}
            <div className='w-full flex flex-wrap'>
                {
                    currentUser?.role_id === RoleEnum.ADMIN &&
                    <div className="w-full flex flex-wrap">
                        <ReactiveFormInput
                            control={updateControl}
                            className='md:w-1/2'
                            label={t("LABEL")}
                            name="label"
                            isRequired />
                        <ReactiveFormRadioButtonSelect
                            className={"w-full px-3 md:w-1/2"}
                            control={updateControl}
                            name="is_personal"
                            label={t("IS_PERSONAL_TAG")}
                            options={[{ label: t("YES"), value: "1" }, { label: t("NO"), value: "0" }]}
                        />
                    </div>
                }
                {
                    currentUser?.role_id === RoleEnum.AGENT &&
                    <div className="w-full">
                        <ReactiveFormInput
                            control={updateControl}
                            className='md:w-1/1'
                            label={t("LABEL")}
                            name="label"
                            isRequired />
                    </div>
                }
            </div>
        </Modal>
    }

    const onUpdateSubmit = (data: any) => {
        setIsUpdatingTag(true);
        
        let isPersonal: boolean = false;
        if(currentUser.role_id === RoleEnum.AGENT) {
            isPersonal = true;
        } else {
            isPersonal = data.is_personal === "1" ? true : false;
        }

        const editTag: NewTagData = {
            label: data.label,
            is_personal: isPersonal
        }

        dispatch(updateTag({ data: editTag, agencyId: currentUser.agency_id, tagId: selectedTag?.id!})).then((e) => {
          if (e.type === "tags/updateTag/rejected") {
            Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info);
            setIsUpdatingTag(false);
          } else {
            toast(t("SAVED_SUCCESFULLY"));
            closeUpdateTagModal();
            setIsUpdatingTag(false);
            setSelectedTag(null);
          }
        })
    };

    const onDeleteSubmit = async(row: any) => {
        dispatch(deleteTag({ agencyId: currentUser.agency_id, tagId: row.id})).then((e) => {
          if (e.type === "tags/deleteTag/rejected") {
            Toast(t(error.reason) ?? t("SOMETHING_WENT_WRONG"), error.cause_info);
            setIsUpdatingTag(false);
          } else {
            toast(t("DELETE_SUCCESFULLY"));
            closeUpdateTagModal();
            setIsUpdatingTag(false);
            setSelectedTag(null);
          }
        })
    };

    return(
        <>
            {isLoading && <LoadingMask />}
            {showCreateTagModal()}
            {showUpdateTagModal()}
            <FilteredTable
                columns={columns}
                data={tags}
                buttonAction={<Button onClick={openCreateTagModal}>
                    <span className='flex items-center font-semibold pr-3'>
                        <div className='w-8 p-1 aspect-square mr-2'><PlusCircleIcon /></div>
                        {t('NEW_TAG')}
                    </span>
                </Button>}
            />
        </>
    );
}