import { useTranslation } from "react-i18next";
import logo from '../assets/images/asureis-side.png';
import picture from '../assets/images/user-picture.png';
import classNames from "classnames";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import FilteredTable from "../components/FilteredTable";
import { useEffect, useMemo } from "react";
import { getCarriers } from "../enums/Carrier";
import { LoadingMask } from "../components/LoadingMask";
import { useDispatch, useSelector } from 'react-redux';
import { fetchPoliciesLeadboard } from "../state/policyLeadboard/actions";
import { AppDispatch } from "../state/store";
import moment from 'moment';
import { fetchTotalPolicySells } from "../state/policySells/actions";
import { useNavigate } from "react-router-dom";
import { PolicySellsReportPath, ProfilePath } from "../constants/Routes";
import { RoleEnum, RoleLabel } from "../enums/Role";
import { ClipboardDocumentListIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import Button from "../components/Button";

const RADIAN = Math.PI / 180;

export const SalesPolicyLeadBoard = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: any) => state.users.currentUser);
    const isLoading = useSelector((state: any) => state.policiesLeadboard.isLoading);
    const policiesLeadboard = useSelector((state: any) => state.policiesLeadboard.policiesLeadboard);
    const dailyTotalPoliciesSell = useSelector((state: any) => state.policySells.dailyTotalPoliciesSell)

    const getLocalTimeZone = () => {
        const timeZoneOffset = new Date().getTimezoneOffset();

        if(timeZoneOffset > 0) {
            const hours = Math.floor(timeZoneOffset / 60);
            const minutes = timeZoneOffset % 60;
            const sHours = `-${hours < 10 ? '0' : ''}${hours}:${String(minutes).padStart(2, "0")}`;
            return sHours;
        } else {
            const hours = Math.floor(timeZoneOffset / 60) * -1;
            const minutes = timeZoneOffset % 60 * -1;
            const sHours = `+${hours < 10 ? '0' : ''}${hours}:${String(minutes).padStart(2, "0")}`;
            return sHours;
        }
    }

    useEffect(() => {
        async function getLeadboardData() {
            if (currentUser) {
                const timeZone = getLocalTimeZone();

                const endDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
                const startDate = moment(endDate).subtract(1, 'weeks').hours(0).minutes(0).seconds(0).toDate();
    
                const end = moment(endDate).format('YYYY-MM-DD');
                const start = moment(startDate).format('YYYY-MM-DD');

                const dailyEndDate = moment(new Date()).hours(23).minutes(59).seconds(59).toDate();
                const dailyStartDate = moment(dailyEndDate).hours(0).minutes(0).seconds(0).toDate();

                const dailyEnd = moment(dailyEndDate).format('YYYY-MM-DD');
                const dailyStart = moment(dailyStartDate).format('YYYY-MM-DD');

                await Promise.all([
                    dispatch(fetchPoliciesLeadboard({ startDate: start, endDate: end, timeZone: timeZone })),
                    dispatch(fetchTotalPolicySells({ startDate: dailyStart, endDate: dailyEnd, timeZone: timeZone })),
                ])
            }
        }

        getLeadboardData();
        const intervalId = setInterval(() => {
            getLeadboardData();
        }, 600000);
        return () => clearInterval(intervalId);
    }, [dispatch, currentUser]);

    const columns = useMemo(() => {
        const showColumns = [
            {
                Header: " ",
                Cell: (row: any) => {
                    return <div className="flex flex-row justify-center">
                        <img src={picture} alt="" className='max-w-11 max-h-11 mx-auto' />
                    </div>
                },
            },
            {Header: t('NAME'),accessor: "name"},
            {Header: 'Aetna', Cell: (row: any) => row.row.original.aetna ?? 0 },
            {Header: 'Ambetter', Cell: (row: any) => row.row.original.ambetter ?? 0 },
            {Header: 'Cigna', Cell: (row: any) => row.row.original.cigna ?? 0 },
            {Header: 'Oscar', Cell: (row: any) => row.row.original.oscar ?? 0 },
            {Header: 'Blue Cross Tx', Cell: (row: any) => row.row.original.blue_cross_tx ?? 0 },
            {Header: 'Christus', Cell: (row: any) => row.row.original.christus ?? 0 },
            {Header: 'Scott & White', Cell: (row: any) => row.row.original.scott_and_white ?? 0 },
            {Header: 'Blue Cross Ok', Cell: (row: any) => row.row.original.blue_cross_ok ?? 0 },
            {Header: 'Anthem', Cell: (row: any) => row.row.original.anthem ?? 0 },
            {Header: 'Caresource', Cell: (row: any) => row.row.original.caresource ?? 0 },
            {Header: 'Medica', Cell: (row: any) => row.row.original.medica ?? 0 },
            {Header: 'Melina', Cell: (row: any) => row.row.original.melina ?? 0 }
        ];

        /*const moreColumns = getCarriers().map(carrier => {
            console.log(carrier);
            return {
                Header: `${carrier.label}`,
                accessor: `${carrier.label.replaceAll(" ", "_").toLocaleLowerCase()}`,
            };
        });*/
        
        return [...showColumns];
    }, [t]);

    return(
        <div className='bg-zinc-100 min-h-screen w-full'>
            {isLoading && <LoadingMask />}
            <div className={
                classNames({
                    "bg-white text-zinc-500": true, // colors
                    "flex items-center": true, // layout
                    "w-screen md:w-full sticky z-10 px-4 shadow-sm h-[70px] top-0 ": true, //positioning & styling
                })
            }>
                <img src={logo} alt="" className='h-6 mr-auto' />
                {
                    currentUser?.role_id == RoleEnum.ADMIN || currentUser?.role_id == RoleEnum.SUPER_ADMIN &&
                    <Button classNames="mr-5" iconOnly onClick={() => navigate(PolicySellsReportPath)} tooltip={t("POLICY_SALES_REPORT")}>
                        <div className='w-6 aspect-square'><ClipboardDocumentListIcon /></div>
                    </Button>
                }
                <div className="md:flex flex-row items-center gap-2 mr-2 cursor-pointer" onClick={() => navigate(ProfilePath)}>
                    <div className="hidden md:block">
                        <div className="text-end text-blue font-medium">{`${currentUser?.first_name ?? ""} ${currentUser?.last_name ?? ""}`}</div>
                        <div className="text-end">{RoleLabel(currentUser?.role_id ?? "")}</div>
                    </div>
                    <div className="relative flex justify-center items-center">
                        <UserCircleIcon className="h-8  aspect-square" />
                    </div>
                </div>
            </div>
            <div className='w-full pt-5 pb-10'>
                <div className="font-bold text-xl px-5">{t("WEEKLY_POLICIES_SALES")}</div>
                
                <div className="w-full grid grid-cols-5">
                    <div className="col-span-5 md:col-span-4">

                        {
                            policiesLeadboard.length > 0 &&
                            policiesLeadboard.map((item: any) => {
                                return <div>
                                    <div className="font-bold text-lg pt-5 pb-2 px-5">{item.business}</div>
                                    <div className="w-full px-5 grow grid grid-cols-1 md:grid-cols-3 gap-3">
                                        {
                                            item.topAgents.length > 0 &&
                                            item.topAgents.map((agent: any) => {
                                                return <div className="w-full relative overflow-hidden bg-white border-slate-300 rounded-lg shadow py-5">
                                                    <div className="w-full flex flex-col">
                                                        <div className="flex flex-row content-end px-5">
                                                            <img src={picture} alt="" className='max-w-12 max-h-12' />
                                                            <div className="text-base text-black flex flex-col justify-end pl-2">
                                                                {agent.name}
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row justify-center items-center">
                                                            <div style={{width: "100%", height: "100%"}}>
                                                                <ResponsiveContainer width={"100%"} height={300}>
                                                                    <PieChart>
                                                                        <Pie
                                                                            data={agent.chart}
                                                                            dataKey="value"
                                                                            nameKey="name"
                                                                            cx="50%"
                                                                            cy="50%"
                                                                            outerRadius={90}
                                                                            innerRadius={65}
                                                                            labelLine={false}
                                                                            label={(a) => renderCustomizedLabel(a, {title: t("TOTAL"), value: agent.agent_total})}
                                                                        >
                                                                            {
                                                                                agent.chart.map((entry: any, index: any) => (
                                                                                    <Cell key={`cell-${index}`} fill={getCarrierColor(entry.name)} />
                                                                                ))
                                                                            }
                                                                        </Pie>
                                                                    </PieChart>
                                                                </ResponsiveContainer>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>

                                    {
                                        /*
                                        item.agents.length > 0 &&
                                        <FilteredTable
                                            columns={columns}
                                            data={item.agents}
                                            hasSearchBar={false}
                                        />*/
                                    }
                                </div>
                            })
                        }
                    </div>

                    <div className="col-span-5 md:col-span-1 mr-5 ml-5 md:ml-0">

                        {
                            dailyTotalPoliciesSell &&
                            <div className="w-full relative overflow-hidden bg-white border-slate-300 rounded-lg shadow py-5 px-5 mt-5">
                                <div className="flex flex-col">
                                    <div className="text-lg font-bold text-black text-center mt-3">
                                        {t("DAILY_TOTAL_POLICIES")}
                                    </div>
                                    <div className="text-asureisGray text-4xl text-center my-3 mx-3">
                                        {dailyTotalPoliciesSell.total}
                                    </div>
                                    <label className="text-center underline cursor-pointer text-blue" onClick={() => navigate(PolicySellsReportPath)}>{t('VIEW_REPORT')}</label>
                                </div>
                            </div>
                        }

                        {
                            policiesLeadboard.length > 0 &&
                            policiesLeadboard.map((item: any) => {
                                return <div className="w-full relative overflow-hidden bg-white border-slate-300 rounded-lg shadow py-5 px-5 mt-5">
                                    <div className="flex flex-col">
                                        <div className="text-lg font-bold text-black text-center mt-3">
                                            {t("TOTAL_POLICIES")}
                                        </div>
                                        <div className="text-lg font-bold text-black text-center">
                                            {item.business}
                                        </div>
                                        <div className="text-asureisGray text-6xl text-center my-3 mx-3">
                                            {item.total}
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                        <div className="w-full relative overflow-hidden bg-white border-slate-300 rounded-lg shadow py-5 px-5 mt-5">
                            <div className="flex flex-col">
                                <div className="text-xl font-bold text-black flex justify-center">
                                    {t("CARRIERS")}
                                </div>
                                {
                                    getCarriers().map((carrier, index) => {
                                        return <div className="flex flex-row items-center justify-center mt-3 mx-3">
                                            <span className="h-2 w-2 mr-1 rounded-full" style={{backgroundColor: getCarrierColor2(carrier.value)}} />
                                            <span className="text-justify" style={{color: getCarrierColor2(carrier.value), fontSize: "17px"}}>
                                                {carrier.label}
                                            </span>
                                        </div>
                                    })
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

const renderCustomizedLabel = (props: any, centerText: any) => {
    const {
        cx,
        cy,
        midAngle,
        outerRadius,
        fill,
        payload,
        percent,
        value
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 0) * cos;
    const sy = cy + (outerRadius + 0) * sin;
    const mx = cx + (outerRadius + 22) * cos;
    const my = cy + (outerRadius + 25) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 10;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
        <g>
            <text x={cx} y={cy} dy={-10} textAnchor="middle" fill="#577082">
                {centerText.title}
            </text>
            <text style={{ fontSize: "22" }} x={cx} y={cy} dy={20} textAnchor="middle" fill="#577082">
                {centerText.value}
            </text>

            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
                style={{ fontSize: "16" }}
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill={fill}
            >
                {value}
            </text>
        </g>
    );
};

const getCarrierColor = (name: string) => {
    const key = name.toLocaleLowerCase();
    return COLORS[key];
}

const getCarrierColor2 = (name: string) => {
    const key = name.toLocaleLowerCase();
    return COLORSC[key];
}

const COLORS: {[k: string]: string} = {
    aetna: "#1abc9c",
    ambetter: "#34495e",
    cigna: "#e74c3c",
    oscar: "#3498db",
    kaiser_permanente: "#b6b975",
    christus: "#f1c40f",
    scott_and_white: "#32de84",
    blue_cross_ok: "#95a5a6",
    anthem: "#9b59b6",
    caresource: "#ffa600",
    medica: "#6c584c",
    molina: "#e88bb3",
    united_health_care: "#9d96b8",
    bcbs: "#cb958e",
    select_health: "#4a6670",
    common_ground: "#eec643",
    alliant: "#f3a712",
    florida_blue: "#a0af84",
    amerihealth: "#f0544f",
    american_health: "#755b69",
    myblue: "#afd2e9",
    wellmark: "#668f80"
}

const COLORSC: {[k: string]: string} = {
    1: "#1abc9c",
    2: "#34495e",
    3: "#e74c3c",
    4: "#3498db",
    5: "#b6b975",
    6: "#f1c40f",
    7: "#32de84",
    8: "#95a5a6",
    9: "#9b59b6",
    10: "#ffa600",
    11: "#6c584c",
    12: "#e88bb3",
    13: "#9d96b8",
    14: "#cb958e",
    15: "#4a6670",
    16: "#eec643",
    17: "#f3a712",
    18: "#a0af84",
    19: "#f0544f",
    20: "#755b69",
    21: "#afd2e9",
    22: "#668f80"
}