import classNames from "classnames";

export const ConversationContainer = (props: {
  children: React.ReactNode;
  onClick: () => void;
  isBold?: boolean;
  name?: string;
  date: string;
}) => {
  return (
    <div
      className={classNames({
        "flex flex-row  gap-3 py-4 cursor-pointer border-b border-black": true,
      })}
      onClick={props.onClick}
    >
      <div className="flex flex-col grow gap-1">
        <div className="flex flex-row justify-between">
          <div className="flex gap-2">
            <div
              className={classNames({
                "text-md text-black relative": true,
                "font-semibold": props.isBold,
              })}
            >
              {props.name}
            </div>
          </div>
          <div className="text-sm font-light text-neutral-500">
            {props.date}
          </div>
        </div>
        <div className="flex gap-2 justify-between items-center">
          {props.children}
        </div>
      </div>
    </div>
  );
};
