import classNames from "classnames";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ChartData } from "../models/ChartData";

interface ChartProps {
  data: ChartData[];
  isLightBlue?: boolean;
  title: string;
  subtitle: string;
}

const Chart = ({ data, isLightBlue, title, subtitle }: ChartProps) => {
  return (
    <div className="w-full relative overflow-hidden bg-white rounded-lg">
      <div className="w-full flex flex-col">
        <div
          className={classNames({
            "text-xl font-bold text-gold mt-8 flex justify-center": true,
            "text-light-blue": isLightBlue,
            "text-gold": !isLightBlue,
          })}
        >
          {title}
        </div>
        <div className="text-blue text-base text-justify mt-3 mx-3 flex justify-center">
          {subtitle}
        </div>
      </div>
      <div style={{ width: "100%", height: "100%" }}>
        <ResponsiveContainer width={"99%"} height={320}>
          <LineChart
            data={data}
            margin={{
              top: 50,
              right: 60,
              left: 20,
              bottom: 50,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
            <Tooltip />
            <YAxis
              stroke="#577082"
              allowDecimals={false}
              interval={"preserveStartEnd"}
            />
            <XAxis dataKey={"key"} stroke="#577082" />
            <Line
              type="monotone"
              dataKey="value"
              stroke={isLightBlue ? "#1f99ef" : "#D4AF37"}
              strokeWidth={4}
              animationDuration={400}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Chart;
