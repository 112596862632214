import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { ChangeEventHandler } from "react";

interface Props {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  isWhite?: boolean;
}
export const SearchBar = (props: Props) => {
  return (
    <div
      className={classNames({
        "w-full max-w-xl flex rounded-full p-1 gap-2 items-center h-10": true,
        "bg-asureis-gray-2": !props.isWhite,
        "bg-white": props.isWhite,
      })}
    >
      <MagnifyingGlassIcon
        width={30}
        height={30}
        className="bg-gold p-1 rounded-full text-white"
      />
      <input
        placeholder="Search"
        type="text"
        className={classNames({
          "outline-none w-full h-full self-center m-1": true,
          "bg-asureis-gray-2": !props.isWhite,
          "bg-white": props.isWhite,
        })}
        onChange={props.onChange}
        value={props.value}
      />
    </div>
  );
};
